import { BsCheck } from "react-icons/bs";
import styled from "styled-components";
import { css } from "styled-components";

export const Single = ({ selected, className }) => {
  return (
    <Circle {...{ selected, className }}>{selected && <BsCheck />}</Circle>
  );
};

export const Multiple = ({ selected, className }) => {
  return (
    <Square {...{ selected, className }}>{selected && <BsCheck />}</Square>
  );
};

const DefaultCon = styled.div`
  display: flex;
  border: 1px solid #d6dbdf;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-size: 1.5rem;
  margin-right: 1rem;
  ${(props) =>
    props.selected &&
    css`
      background: #ff4f00;
      color: white;
      border: 1px solid transparent;
    `}
`;
const Square = styled(DefaultCon)`
  border-radius: 2px;
`;
const Circle = styled(DefaultCon)`
  border-radius: 50%;
`;
