import * as React from "react";
import { Formik, Form } from "formik";
import { Input } from "components/input";
import { Button } from "components/button";
import styled from "styled-components";
import * as Yup from "yup";
import { useMutation } from "react-query";
import axios from "axios";
import { useModal } from "contexts/modalContext";
import PhoneNumberInput from "components/input/phoneNumberInput";
import { useTranslation } from 'react-i18next';

const initialValues = {
  name: "",
  industry: "",
  first: "",
  last: "",
  phone: "",
  email: "",
  username: "",
  password: "",
};

const validateSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  industry: Yup.string().required("Required"),
  first: Yup.string().required("Required"),
  last: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

export default function WaitListModal() {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.home.container.waitlist.waitListModal";
  const tr = (key) => t(`${tBase}.${key}`);
  
  const { setShow } = useModal();
  const submit = useMutation(
    async (body) =>
      await axios.post(
        `
    ${process.env.REACT_APP_API}/api/v1/consumer/business-sign-up
    `,
        body
      ),
    {
      onSuccess: () => window.location.replace("https://pro.eserv.com"),
    }
  );
  const handleSubmit = (e) => {
    submit.mutate(e);
    // console.log(e);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validateSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <SForm>
          <span>{tr("Business")}</span>
          <Input
            required
            autoFocus
            formik={props}
            name="name"
            // onChange={props.handleChange}
            // onBlur={props.handleBlur}
            // error={props.touched["name"] && props.errors["name"]}
            label={tr("Business name")}
            size="large"
          />
          <Input
            required
            name="industry"
            formik={props}
            // onChange={props.handleChange}
            // onBlur={props.handleBlur}
            // error={props.touched["industry"] && props.errors["industry"]}
            label={tr("Industry")}
            size="large"
          />
          <span>{tr("Personal")}</span>
          <Row>
            <Input
              required
              name="first"
              formik={props}
              // onChange={props.handleChange}
              // onBlur={props.handleBlur}
              // error={props.touched["first"] && props.errors["first"]}
              label={tr("First Name")}
              size="large"
            />
            <Input
              required
              formik={props}
              name="last"
              // onChange={props.handleChange}
              // onBlur={props.handleBlur}
              // error={props.touched["last"] && props.errors["last"]}
              label={tr("Last Name")}
              size="large"
            />
          </Row>
          <PhoneNumberInput
            required
            formik={props}
            name="phone"
            // type="number"
            // onChange={props.handleChange}
            // onBlur={props.handleBlur}
            // error={props.touched["phone"] && props.errors["phone"]}
            label={tr("Phone number")}
            size="large"
          />
          <Input
            required
            formik={props}
            name="email"
            // onChange={props.handleChange}
            // onBlur={props.handleBlur}
            // error={props.touched["email"] && props.errors["email"]}
            label={tr("Email")}
            size="large"
          />

          <Input
            formik={props}
            required
            name="username"
            // onChange={props.handleChange}
            // onBlur={props.handleBlur}
            // error={props.touched["username"] && props.errors["username"]}
            label={tr("Username")}
            size="large"
          />
          <Input
            required
            formik={props}
            type="password"
            // onChange={props.handleChange}
            // onBlur={props.handleBlur}
            // error={props.touched["password"] && props.errors["password"]}
            name="password"
            label={tr("Password")}
            size="large"
          />
          <div>
            <Button isLoading={submit.isLoading} primary type="submit">
              {tr("Sign Up")}
            </Button>
          </div>
        </SForm>
      )}
    </Formik>
  );
}

const Row = styled.div`
  display: flex;
  gap: 1rem;
  > div {
    width: 100%;
  }
`;
const SForm = styled(Form)`
  width: 600px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  > div:last-child {
    align-self: flex-end;
    > button {
      width: 200px;
    }
  }
  > span {
    font-weight: bold;
    border-bottom: 1px solid #c0c0c0;
  }
`;
