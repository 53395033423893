import axios from "axios";
import { Button } from "components/button";
import FieldInput from "components/field";
import SafeArea from "components/safearea";
import { Form, Formik } from "formik";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
// @ts-ignore
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';

export default function PasswordReset() {
  const params = useParams();

  return (
    <SafeArea style={{ maxWidth: "500px" }}>
      {params.type === "forget" ? (
        <ForgetPassword />
      ) : (
        <ResetPassword id={params.id} />
      )}
    </SafeArea>
  );
}

const ForgetPassword = () => {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.passwordReset.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const history = useHistory();
  const mutate = useMutation(
    async (e) =>
      await axios.post(
        `
    ${process.env.REACT_APP_API}/api/v1/consumer/password/forget
    `,
        e
      )
  );
  const handleSubmit = (e) => {
    mutate.mutate(e);
  };

  return (
    <>
      {mutate.isSuccess ? (
        <>
          <p>
            {tr("Please follow the instruction sent to your email to reset your password.")}
          </p>
          <Button onClick={() => history.push("/company")}>Back to Home</Button>
        </>
      ) : (
        <Formik
          initialValues={{ email: "" }}
          validationSchema={Yup.object({
            email: Yup.string().email().required(),
          })}
          onSubmit={handleSubmit}
        >
          <Form>
            <p>{tr("Please enter your email address to reset your password.")}</p>
            <FieldInput type="email" label={tr("Email")} name="email" />
            <Button type="submit">{tr("Submit")}</Button>
          </Form>
        </Formik>
      )}
    </>
  );
};

const ResetPassword = ({ id }) => {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.passwordReset.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const history = useHistory();
  const query = useQuery(
    "validate-password",
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_API}/api/v1/consumer/password/${id}
    `
        )
        .then((res) => res.data)
  );

  const mutate = useMutation(async (e) =>
    axios.post(
      `
    ${process.env.REACT_APP_API}/api/v1/consumer/password/reset/${id}
    `,
      e
    )
  );

  const handleSubmit = (e) => {
    mutate.mutate({ password: e.password });
  };

  if (!query.isSuccess) {
    return <p>{tr("Invalid")}</p>;
  }

  if (mutate.isSuccess) {
    return (
      <>
        <p>
          {tr("Password has been reset. Please navigate to home and sign in with your new password!")}
        </p>
        <Button onClick={() => history.push("/company")}>{tr("Back to Home")}</Button>
      </>
    );
  }
  return (
    <Formik
      initialValues={{ password: "", verifyPassword: "" }}
      validationSchema={Yup.object({
        password: Yup.string().min(6).required(),
        verifyPassword: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Password does not match"
        ),
      })}
      onSubmit={handleSubmit}
    >
      <Form>
        <p>{tr("Please enter your new password")}:</p>

        <FieldInput type="password" label="New Password" name="password" />
        <FieldInput
          type="password"
          label={tr("Verify Password")}
          name="verifyPassword"
        />
        <Button type="submit" isLoading={mutate.isLoading}>
          {tr("Submit")}
        </Button>
      </Form>
    </Formik>
  );
};
