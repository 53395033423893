export const dollarFormatter = (value) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value / 100);

export const dollarInputField = {
  get: (value) => (value / 100).toFixed(2),
  format: (value) => parseInt(value.replace(/[^\d]/, "").replace(".", "") || 0),
};
