import SafeArea from "components/safearea";
import * as React from "react";
import styled from "styled-components";
import UsingTablet from "images/usingTablet.jpg";
import { H3, ImageCon, P } from "../../style";
import { Button } from "components/button";
import useWindowDimensions from "hooks/useWindowDimensions";
import ContactSales from "../../components/contactSalesButton";
import { useTranslation } from 'react-i18next';

export default function LearnMoreSection() {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.home.container.section.questionBuilder";
  const tr = (key) => t(`${tBase}.${key}`);

  const { width } = useWindowDimensions();
  return (
    <Con>
      <SSafeArea padding flex="row">
        {width > 1300 ? (
          <>
            <div>
              <H3>{tr("Build personalized questions")}</H3>
              <P>
                {tr("No more wondering what the customer need. Get started with your own personalized questions to minimize customer onboarding time and maximize consistency.")}
              </P>
              <ContactSales />
            </div>
            <SImageCon>
              <img src={UsingTablet} />
            </SImageCon>
          </>
        ) : (
          <>
            <SImageCon>
              <img src={UsingTablet} />
            </SImageCon>
            <div>
              <H3>{tr("Build personalized questions")}</H3>
              <P>
                {tr("No more wondering what the customer need. Get started with your own personalized questions to minimize customer onboarding time and maximize consistency.")}
              </P>
              <ContactSales />
            </div>
          </>
        )}
      </SSafeArea>
    </Con>
  );
}

const SImageCon = styled(ImageCon)`
  > img {
    object-fit: cover;
    width: 100%;
  }
`;

const Con = styled.div`
  display: flex;
`;

const SSafeArea = styled(SafeArea)`
  display: flex;
  justify-content: center;
  align-items: center;
  > div:first-child {
    flex: 0 0 40%;
    margin: 1rem;
  }
  @media screen and (max-width: 1300px) {
    flex-direction: column;
    > div:first-child {
      margin: 0;
      flex: 1;
    }
  }
`;
