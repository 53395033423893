import styled from "styled-components";
import { useEffect } from "react";
import { useAuth } from "contexts/authContext";
import { useMutation } from "react-query";
import Axios from "axios";
import { Button } from "components/button";
import { formatPhoneNumber } from "./utils";
import PhoneNumberInput from "components/input/phoneNumberInput";
import StandardizedAddressInput from "components/standardizedAddressInput";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Input } from "components/input";
import style from "./style.module.css"
import { useTranslation } from 'react-i18next';

// unused 
const InputWithTooltip = ({ label, name, placeholder, type, inputRegister, errors }) => {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.signin.signup";
  const tr = (key) => t(`${tBase}.${key}`);

  console.log("input: ", errors[name])
  return (
    <div style={{ position: 'relative' }}>
      <label>{label}</label>
      <input
        name={name}
        placeholder={placeholder}
        type={type}
        {...inputRegister(name, { required: {value : true, message: tr("This field is required")} })}
      />
      {errors[name] && (
        <div className={style.tooltip}>
          {errors[name].message}
        </div>
      )}
    </div>
  );
};


export default function SignUp({ setErrorMessage }) {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.signin.signup";
  const tr = (key) => t(`${tBase}.${key}`);

  const { register } = useAuth();
  const [isPrivacyPolicyAck, setIsPrivacyPolicyAck] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);


  // prompt browser for a longitude and latitude
  // if the user accepts, the browser will return the coordinates

  // if the user denies, the browser will return an error
  // if the user ignores the prompt, the browser will return an error
  const coords = navigator.geolocation.getCurrentPosition(
    (position) => {
      console.log("position: ", position)
    },
    (error) => {
      console.log("error: ", error)
    }
  )

  const longitude = coords?.coords?.longitude;
  const latitude = coords?.coords?.latitude;

  const [addressObj, setAddressObj] = useState({
    address: "",
    isStandardizedAddress: false,
    selectedOption: {
      description: "",
      city: "",
      state: "",
      zipcode: "",
    },
  });

  React.useEffect(() => {
    setValue("street_address", addressObj.address);
    setValue("city", addressObj.selectedOption.city);
    setValue("state", addressObj.selectedOption.state);
    setValue("zip_code", addressObj.selectedOption.zipcode);
  }, [addressObj]) 
  
  const updateAddressValue = (name, value) => {
    setAddressObj({
      ...addressObj,
      selectedOption: {
        ...addressObj.selectedOption,
        [name]: value,
      },
    });
  }

  const { mutate, isSuccess, isLoading } = useMutation(
    async (value) => {
      value = {
        ...value,
        phoneNumber: value.phoneNumber.replace(/\D/g, ''), // Remove all non-digits
      }
      return await Axios.post(
        `${process.env.REACT_APP_API}/signup`, {...value, street_address: addressObj.address, isStandardizedAddress: addressObj.isStandardizedAddress}
      ).then((res) => res.data);
    },
    {
      onError: (err) => {
        if (err.response.status === 409) {
          return setErrorMessage("Duplicated email found in the system.");
        }
        return setErrorMessage("An error has occurred");
      },
    }
  );

  const onSubmit = (value) => {
    mutate(value);
  };

  useEffect(() => {
    if (isSuccess) return register();
  }, [isSuccess]);


  const { register: inputRegister, handleSubmit, formState: {errors}, setValue } = useForm({
    mode: "onChange",
    delayError: 5000
  });


  console.log(Object.keys(errors).length)
  console.log(errors)


  // TODO: add error messages for each input, preferably tool tip messages
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ margin: "1rem 0", display: "flex", flexDirection: "column" }} >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Input
          label={tr("First Name")}
          name={"firstname"}
          placeholder="John"
          type={"text"}
          {...inputRegister("firstname", { required: true })}
        />
        <Input
          label={tr("Last Name")}
          name={"lastname"}
          placeholder="Doe"
          type={"text"}
          {...inputRegister("lastname", { required: true })}
        />
      </div>
      <div>
        <Input
          label={tr("Email")}
          name={"email"}
          type={"email"}
          placeholder="abc@domain.com"
          {...inputRegister("email", { required: true })}
        />
      </div>
      <div>
      <Input
        label={tr("Phone Number")}
        name={"phoneNumber"}
        placeholder="(XXX) XXX-XXXX"
        type="text" // Changed from 'number' to 'text' to allow formatted input
        {...inputRegister("phoneNumber", { 
          required: tr("Phone number is required"),
          // pattern is causing some issues
          // pattern: {
          //   value: /^\(\d{3}\) \d{3}-\d{4}$/, // This pattern matches the format (XXX) XXX-XXXX
          //   message: "Invalid phone number"
          // },
          onChange: (e) => {
            // Format as (XXX) XXX-XXXX
            let inputNumbersValue = e.target.value.replace(/\D/g, ''); // Remove all non-digits
            let formattedInputValue = '';

            // We add parenthesis and hyphens at the appropriate places
            if (inputNumbersValue.length > 6) {
              formattedInputValue = `(${inputNumbersValue.substring(0, 3)}) ${inputNumbersValue.substring(3, 6)}-${inputNumbersValue.substring(6, 10)}`;
            } else if (inputNumbersValue.length > 3) {
              formattedInputValue = `(${inputNumbersValue.substring(0, 3)}) ${inputNumbersValue.substring(3)}`;
            } else if (inputNumbersValue.length > 0) {
              formattedInputValue = `(${inputNumbersValue}`;
            }

            e.target.value = formattedInputValue; // Update the input with the formatted value
          }
        })} 
      />
      {/* input subtext beneath the phone number input */}
      {/* change color to red */}
      <sub style={{ color: "#FF7777" }}>
        By providing a telephone number and submitting this form you are consenting to be contacted by SMS text message. Message & data rates may apply. You can reply STOP to opt-out of further messaging.
      </sub>
      </div>
      <div>
        {/* <StandardizedAddressInput
          label="Address"
          name="street_address"
          setAddressObj={setAddressObj}
          register={inputRegister}
          setValue={setValue}
          longitude={longitude}
          latitude={latitude}
          required
        /> */}
        <Input
          label={tr("Address")}
          name="street_address"
          placeholder="1234 Main St"
          type={"text"}
          addrValue={addressObj.address}
          {...inputRegister("street_address", { required: true })}
          onChange={(e) => setAddressObj({ ...addressObj, address: e.target.value })}
        />
      </div>
      <Row>
        <Input
          label={tr("City")}
          name="city"
          placeholder="Lincoln"
          type={"text"}
          addrValue={addressObj.selectedOption ? addressObj.selectedOption.city : ""}
          {...inputRegister("city", { required: true })}
          style={{ width: "200px" }}
        />
        <Input
          label={tr("State")}
          name="state"
          placeholder="NE"
          type={"text"}
          addrValue={addressObj.selectedOption ? addressObj.selectedOption.state : ""}
          {...inputRegister("state", { required: true })}
          style={{ width: "100px"}}
        />
        <Input
          label={tr("Zip Code")}
          name="zip_code"
          placeholder="12345"
          type={"text"}
          addrValue={addressObj.selectedOption ? addressObj.selectedOption.zipcode : ""}
          {...inputRegister("zip_code", { required: true })}
          style={{ width: "100px"}}
        />
      </Row>
      <div>
        <Input
          label={tr("Password")}
          name={"password"}
          type={"password"}
          {...inputRegister("password", { required: true })}
        />
      </div>

      
      <div style={{ display: "flex", gap:"5px" }}>
        <input type="checkbox" name="privacyPolicy" onChange={(e) => setIsPrivacyPolicyAck(e.target.checked)} /> 
        {tr("I have read and agree to the")} <a href="/privacy">{tr("Privacy Policy")}</a>
      </div>
      
      <Button
        type="submit"
        primary
        isLoading={isLoading}
        label={tr("Sign Up")}
        disabled={Object.keys(errors).length > 0 || !isPrivacyPolicyAck}
        style={{
          margin: "1rem 0",
          borderRadius: "25px",
        }}
      >
        {tr("Sign Up")}
      </Button>
    </form>
  )

}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Select = styled.select`
  border-radius: 5px;
  border: 1px solid #c0c0c0;
  height: 30px;
  width: 100px;
`;
