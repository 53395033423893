import ImageUploadButton from "components/button/imageUploadButton";
import styled from "styled-components";
import { IconButton, SubtleIconButton } from "components/button";
import { GrClose } from "react-icons/gr";
import { useTranslation } from 'react-i18next';

export default function ImageUploadQuestion({
  selectedOption: images,
  setSelectedOption: setImages,
}) {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.question.questionType.imageUploadQuestion";
  const tr = (key) => t(`${tBase}.${key}`);

  const uploadImages = (image) => {
    setImages({
      id: image.blob.name,
      option: image.blob,
      dataUrl: image.dataUrl,
    });
  };

  const populateImages = images?.map((image) => (
    <Image key={image.id} image={image} removeImage={() => setImages(image)} />
  ));
  return (
    <div>
      <div>{tr("Max")}: 4</div>
      <ImageUploadButton {...{ uploadImages }} multiple />
      <ImagePreviewCon>{populateImages}</ImagePreviewCon>
    </div>
  );
}

const ImagePreviewCon = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0.5rem 0;
  gap: 0.25rem;
`;

const Image = ({ image, removeImage }) => {
  return (
    <ImageCon>
      <img src={image.dataUrl} alt={image.option.name} />
      <SubtleIconButton onClick={removeImage}>
        <GrClose />
      </SubtleIconButton>
    </ImageCon>
  );
};

const ImageCon = styled.div`
  height: 250px;
  overflow: hidden;
  display: flex;
  border-radius: 20px;
  justify-content: center;
  position: relative;
  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  > button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
  }
`;
