import { ReactNode } from "react";
import styled, { css } from "styled-components";
import { string } from "yup";
import { theme } from "styles/theme";

const containerSize = (size?: string) => {
  switch (size) {
    case "large":
      return `
      font-size: 1rem;
      padding: 0.6em;
      `;

    case "small":
      return `
      font-size: 0.8rem;
      `;
    default:
      return `
      font-size: 0.9rem;
      `;
  }
};

const labelSize = (size?: string) => {
  switch (size) {
    case "large":
      return `
      font-size: 0.9rem;
      `;

    case "small":
      return `
      font-size: 0.8rem;
      `;
    default:
      return `
      font-size: 0.8rem;
      `;
  }
};

export const StyledTextArea = styled.textarea<{
  size?: string;
  error?: string;
}>`
  outline: none;
  font-weight: 500;
  border: 1px solid ${(props) => (props.error ? "red" : "transparent")};
  border-radius: 5px;
  border: 1px solid transparent;
  background: #f0f0f0;
  margin: 0;
  padding: 0.5em;
  transition: all 0.09s linear;
  ${(props) => containerSize(props.size)};
  &:hover {
    border: 1px solid #5d6d7e;
  }
  &:focus {
    background: transparent;
    border: 1px solid #3498db;
  }
`;

export const StyledInput = styled.input<{
  size?: string;
  error?: string;
  search?: boolean;
}>`
  outline: none;
  font-weight: 500;
  border: 1px solid ${(props) => (props.error ? "red" : theme.border.light)};
  border-radius: 5px;
  background: ${(props) => (props.error ? theme.bg.lightred : "white")};
  /* background: ${theme.bg.blue}; */
  margin: 0;
  height: ${(props) => theme.height[props.size || "regular"]};
  padding: 0 1em;
  padding-left: ${(props) => props.search && "2em"};
  transition: all 0.09s linear;
  font-size: ${(props) => theme.fontSize[props.size || "regular"]};
  /* ${(props) => containerSize(props.size)}; */
  &:disabled {
    background: ${theme.bg.blue};
    cursor: not-allowed;
  }
  &:hover {
    border: 1px solid #5d6d7e;
  }
  &:focus {
    background: transparent;
    border: 1px solid #3498db;
  }
`;

export const StyledTransparentInput = styled(StyledInput)`
  background: transparent;
`;

export const StyledSemiTransparentInput = styled(StyledInput)`
  background: rgba(255, 255, 255, 0.4);
  border: 0;
`;

export const Con = styled.div<{ horizontal?: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.horizontal &&
    css`
      flex-direction: row;
    `};
`;

export const Asterick = styled.span`
  color: red;
`;

export const Error = styled.span`
  color: red;
  font-weight: bold;
  font-size: 0.8rem;
`;

export const StyledLabel = styled.label<{ size?: string }>`
  ${(props) => labelSize(props.size)};
  font-weight: 600;
  line-height: 2;
`;

export const StyledCheckbox: any = styled(StyledInput)`
  border: 1px solid green;
  width: 25px;
  height: 25px;
`;

export const StyledRadio: any = styled(StyledInput)`
  background: black;
`;

export const InputCon = styled.div<{ horizontal?: boolean; hasLogo?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: ${(props) => (props.horizontal ? "0 0 60%" : "1")};
  > svg {
    height: 100%;
    top: 0;
    font-size: 1.2em;
    position: absolute;
    pointer-events: none;
    &:first-child {
      left: 0;
      transform: translate(50%, 0);
    }
  }
  > div:last-child {
    height: 100%;
    top: 0;
    font-size: 1.2em;
    position: absolute;
    pointer-events: none;
    right: 0;
    transform: translate(-50%, 0);
  }
  ${(props) =>
    props.hasLogo &&
    css`
      > input {
        padding-left: 2rem;
      }
    `}
`;

export const StyledSearchInput = styled(StyledInput)`
  border-radius: 30px;
  width: calc(100% - 1em - 2px -1.5em);
`;
