import axios from "axios";
import SafeArea from "components/safearea";
import { format } from "date-fns";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { TInvoice } from "types/invoice";
import { dollarFormatter } from "utils";
import { useTranslation } from 'react-i18next';

export default function Invoices() {
  const { t } = useTranslation();
  const tBase = "pages.protected.invoices.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const history = useHistory();

  const query = useQuery<TInvoice[] | null>(
    ["payments"],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_API}/api/v1/consumer/invoices
  `
        )
        .then((res) => res.data)
  );

  const populateInvoices = query.data?.map((invoice) => {
    return (
      <div
        key={invoice.id}
        className="border-solid border-slate-400 border p-2 my-4 rounded flex flex-col items-start"
        onClick={() => history.push(`invoices/${invoice.encryptedId}`)}
      >
        <div>
          <span className="text-xs font-bold text-slate-500">
            {format(new Date(invoice.creation_date), "MMM dd, yyyy")}
          </span>
        </div>
        <h2>{invoice.company}</h2>
        <div
          className={`py-1 px-2 text-xs rounded font-bold my-2`}
          style={{
            color: `rgb(${invoice.status.color})`,
            background: `rgba(${invoice.status.color},0.2)`,
          }}
        >
          {invoice.status.label}
        </div>
        <div className="text-3xl text-green-700">
          {dollarFormatter(invoice.actual_total)}
        </div>
      </div>
    );
  }) || <span>{tr("Empty")}</span>;

  return (
    <SafeArea>
      <div className="p-2">
        <div>
          <h1>{tr("Invoices")}</h1>
        </div>
        <div>{populateInvoices}</div>
      </div>
    </SafeArea>
  );
}
