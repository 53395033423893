import { AuthProvider } from "./authContext";
import { RequestProvider } from "../pages/unprotected/question/requestContext";
import { ModalProvider } from "./modalContext";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

export default function ({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ModalProvider>
          <RequestProvider>{children}</RequestProvider>
        </ModalProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
