import { Single, Multiple } from "components/questionType";
import styled, { css } from "styled-components";
import { useRequest } from "../requestContext";
import ImageUploadQuestion from "./imageUploadQuestion";
import TextQuestion from "./text";
import { useTranslation } from 'react-i18next';

const OptionCon = styled.div`
  padding: 0.5rem;
  border: 1px solid #d6dbdf;
  cursor: pointer;
  border-radius: ${(props) => (props.type === "SINGLE" ? "25px" : "5px")};
  box-shadow: 0 0 10px 0 #f0f0f0;
  margin: 1rem 0;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  ${(props) =>
    props.selected &&
    css`
      border: 1px solid #ff4f00;
      font-weight: bold;
      color: #ff4f00;
    `}
`;

export default function Options({
  options,
  selectedOption,
  setSelectedOption,
  type,
}) {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.question.questionType.options";
  const tr = (key) => t(`${tBase}.${key}`);

  const { dispatch } = useRequest();

  const handleOptionClick = (option) => {
    if (type === "SINGLE") {
      setSelectedOption(option);
    } else {
      setSelectedOption(option);
    }
  };

  const populateOptions = options?.map((option) => {
    const selectedIdx = selectedOption.findIndex(({ id }) => id === option.id);
    const selected = selectedIdx !== -1;

    const populateSubQuestions = option.subquestions?.map((subquestion) => {
      const selectedSubquestionOption =
        selectedOption[selectedIdx]?.subquestions?.find(
          (subq) => subq.id === subquestion.id
        )?.response || [];

      const renderOptions = () => {
        switch (subquestion.type) {
          case "TEXT":
            return (
              <TextQuestion
                {...{ selectedOption: selectedSubquestionOption }}
                setSelectedOption={(e) =>
                  dispatch({
                    type: "selectSubquestionOption",
                    e,
                    subquestionId: subquestion.id,
                    subquestionType: subquestion.type,
                    optionId: option.id,
                  })
                }
              />
            );
          case "IMAGE":
            return (
              <ImageUploadQuestion
                {...{ selectedOption: selectedSubquestionOption }}
                setSelectedOption={(e) =>
                  dispatch({
                    type: "selectSubquestionOption",
                    e,
                    subquestionId: subquestion.id,
                    subquestionType: subquestion.type,
                    optionId: option.id,
                  })
                }
              />
            );
          default:
            return (
              <Options
                {...{
                  options: subquestion.options,
                  selectedOption: selectedSubquestionOption,
                  type: subquestion.type,
                }}
                setSelectedOption={(e) =>
                  dispatch({
                    type: "selectSubquestionOption",
                    e,
                    subquestionId: subquestion.id,
                    subquestionType: subquestion.type,
                    optionId: option.id,
                  })
                }
              />
            );
        }
      };

      return (
        <div key={subquestion.id} className="py-2">
          <div className="flex items-center space-x-2">
            <span>{subquestion.question}</span>
            {subquestion.required && (
              <span className="text-white bg-red-600 p-1 text-[0.6rem] rounded font-bold uppercase">
                {tr("Required")}
              </span>
            )}
          </div>

          {renderOptions()}
        </div>
      );
    });

    return (
      <OptionCon
        selected={selected}
        key={option.id}
        type={type}
        onClick={(e) => {
          e.stopPropagation();
          handleOptionClick(option);
        }}
      >
        <div className="flex items-center">
          {type === "SINGLE" ? (
            <Single {...{ selected }} className="text-[1.5rem] mr-1" />
          ) : (
            <Multiple {...{ selected }} className="text-[1.5rem] mr-1" />
          )}
          {option.option}
        </div>
        {selected && option.fk_subquestion_id && (
          <div
            className="py-2 px-6 border-slate-200 border my-2 ml-10 mr-4 flex flex-col text-black
          animate-[wiggle_0.25s_ease-out] divide-y
          "
          >
            {populateSubQuestions}
          </div>
        )}
      </OptionCon>
    );
  });
  return <div>{populateOptions}</div>;
}
