import { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// import { useQuery, useMutation } from "react-query";
import Axios from "axios";

Axios.defaults.withCredentials = true;
Axios.defaults.crossDomain = true;
const AuthContext = createContext();

function AuthProvider(props) {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  // const location = useLocation();

  // const getLogin = async () => {
  //   await Axios.get(`${process.env.REACT_APP_API}/loginStatus`);
  //   setIsLoading(false);
  //   setIsAuth(true);
  // }

  // const loginStatus = useQuery('login', getLogin)

  useEffect(() => {
    const checkLogin = async () => {
      try {
        await Axios.get(`${process.env.REACT_APP_API}/loginStatus`);
        setIsAuth(true);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    checkLogin();
  }, []);

  const login = () => setIsAuth(true);

  const logout = async () => {
    try {
      await Axios.post(`${process.env.REACT_APP_API}/signout`);
      setIsAuth(false);
      history.push("/company");
    } catch (e) {
      console.error(e);
    }
  };

  const register = () => setIsAuth(true);

  return (
    <AuthContext.Provider
      value={{ isLoading, isAuth, logout, register, login }}
      children={isLoading ? <div>Loading</div> : props.children}
    />
  );
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
