import * as React from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

export default function ContactSales() {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.home.components.contactSalesButton";
  const tr = (key) => t(`${tBase}.${key}`);

  return (
    <A
      href={`
              mailto:wl@eserv.com?subject=Inquiry
              &body=
              `}
    >
      {tr("Contact Us")}
    </A>
  );
}

const A = styled.a`
  background: #ff4f00;
  border-radius: 25px;
  padding: 0.5em 1em;
  line-height: 1;
  color: white;
  text-decoration: none;
`;
