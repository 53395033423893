import styled from "styled-components";
import { Button, OutlineIconButton } from ".";
import Tooltip from "../tooltip";
import TextArea from "react-textarea-autosize";
import { useMutation } from "react-query";
import Axios from "axios";
import React, { useState } from "react";
import { RiFeedbackLine } from "react-icons/ri";
import { useTranslation } from 'react-i18next';

export default function FloatingButton() {
  const { t } = useTranslation();
  const tBase = "components.button";
  const tr = (key) => t(`${tBase}.${key}`);

  const [value, setValue] = useState("");

  const { mutate, status } = useMutation((feedback) =>
    Axios.post(
      `
      ${process.env.REACT_APP_API}/api/v1/consumer/feedback
      `,
      feedback
    )
  );
  return (
    <Con>
      <Tooltip
        trigger={
          <OutlineIconButton size="large" primary>
            <RiFeedbackLine />
          </OutlineIconButton>
        }
        tooltip={
          <TooltipCon>
            {status.isSuccess ? (
              <>
                <h2>{tr("Thank you for the input!")}</h2>
              </>
            ) : (
              <>
                <h2>{tr("We would like to hear from you!")}</h2>
                <TextArea
                  value={value}
                  rows={4}
                  onChange={(e) => setValue(e.target.value)}
                />
                <Button
                  onClick={() => {
                    mutate({ label: value });
                  }}
                >
                  {tr("Submit")}
                </Button>
              </>
            )}
          </TooltipCon>
        }
      />
    </Con>
  );
}

const TooltipCon = styled.div`
  padding: 0.5rem;
  > h2 {
    margin: 0;
  }
`;

const Con = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1rem;
`;

// const Button = styled(ButtonComp)`
//   background: white;
//   color: #ff4f00;
//   border: 2px solid #ff4f00;
//   box-shadow: 1px 0 10px -5px grey;
// `;
