import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import styled from "styled-components";
import { Asterick, Error, StyledLabel } from "components/input/style";
import { Input } from "components/input";
import styles from "./styles";
import { useTranslation } from 'react-i18next';

const Option = ({option, ...props}: any) => {
	const { t } = useTranslation();
	const tBase = "components.standardizedAddressInput";
	const tr = (key: string) => t(`${tBase}.${key}`);

	const [hovered, setHovered] = React.useState(false);
	const { setSelectedOption, setIsStandardizedAddress, clickedAddress } = props;

	return (
		<div
			style={{ ...styles.option, backgroundColor: hovered ? "#a6a6a6" : "" }}
			onClick={() => { setSelectedOption(option); setIsStandardizedAddress(true); clickedAddress(option, true);}}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			{option.description}
		</div>
	);
};

const AddressInput = (props:any) => {
	const { t } = useTranslation();
	const tBase = "components.standardizedAddressInput";
	const tr = (key: string) => t(`${tBase}.${key}`);
	const { selectedOption, setAddress, address, setIsStandardizedAddress, setSelectedOption } = props

	const handleInputChange = (e: any) => {
		setAddress(e.target.value)

		setSelectedOption({
			description: "",
			city: "",
			state: "",
			zipcode: "",
		})
	};

	const handleKeyPress = (e: any) => {
		if (e.key === 'Enter' || e.keyCode === 13) {
			setAddress(e.target.value)
			setIsStandardizedAddress(false);
			setSelectedOption({
				description: e.target.value,
				city: "",
				state: "",
				zipcode: "",
			})

			return;
		}
	}

	return (
		<div style={{width:"100%"}}>
			<Input
				type="text"
				onKeyDown={(e)=>handleKeyPress(e)}
				value={address}
				onKeyPress={props.onKeyPress}
				{...props?.register("street_address", {
					required: props.required ? tr("Address is required") : false,
					onChange: (e:any) => {
						handleInputChange(e)
					}
				})}
			/>
			{/* disabled because disabled places api */}
			{/* {address.length > 0 && selectedOption?.description === "" && (
				<div style={styles.optionBox}>
					{
						props.options.map ?
							props.options.map((option: any, index: number) => 
								<Option key={index} {...props} option={option} />
							)
						: <div style={{textAlign:"center"}}>No Suggestions Found</div>
					}
				</div>
			)} */}
		</div>
	);
};

const StandardizedAddressInput = (props:any) => {
	const { t } = useTranslation();
	const tBase = "components.standardizedAddressInput";
	const tr = (key: string) => t(`${tBase}.${key}`);

	const [address, setAddress] = useState("");
	const [suggestions, setSuggestions] = useState<any[]>([]);
	const [isStandardizedAddress, setIsStandardizedAddress] = useState<boolean>(false);
	const [selectedOption, setSelectedOption] = React.useState(
		{
			description: "",
			city: "",
			state: "",
			zipcode: "",
		}
	)

    useEffect(() => {
		getAddressSuggestions()
    }, [address]);
	
	const clickedAddress = (option:any, isStandardizedAddress:boolean) => {
		props.setValue("street_address", option.description)
		setAddress(option.description)
		props.setAddressObj({address: option.description, isStandardizedAddress: isStandardizedAddress, selectedOption: option})
	}

	const cachedSuggestionsRef = useRef<any[]>([])
	const cancelTokenSource = useRef<any>(axios.CancelToken.source())

	const getAddressSuggestions = async () => {

		// cancels an existing request if the user types in a new input before previous request being resolved
		if (cancelTokenSource.current) {
			cancelTokenSource.current.cancel(`${tr("Request cancelled for")} ${address}`)
		}

		cancelTokenSource.current = axios.CancelToken.source()

		// basic caching of address suggestions based on input string
		if (cachedSuggestionsRef.current.some(suggestion => suggestion[address])) {
			const cachedSuggestion = cachedSuggestionsRef.current.find(suggestion => suggestion[address])
			setSuggestions(cachedSuggestion[address])
			return
		}
		
		try {
			await axios.get(
				`
					${process.env.REACT_APP_API}/api/v1/consumer/general/autoCompleteAddress
				`,
				{
					params: {
						type: "autoCompleteAddress",
						input: address,
						longitude: props.longitude,
						latitude: props.latitude,
					},
					cancelToken: cancelTokenSource.current.token
				}
			).then(res => {
				cachedSuggestionsRef.current.push({[address]: res.data})
				setSuggestions(res.data)
			})
		}
		catch (error) {
			if (axios.isCancel(error)) {
				console.log(`${tr("Request cancelled for")} ${address}`)
			}
			else {
				console.log(error)
			}
		}
	}

    return (
		<Container style={{ ...props.style, position: "relative" }}>
			{props.label && (
				<StyledLabel>
					{props.label}
					{props.required && <Asterick className="text-[0.6rem] ml-1">{tr("Required")}</Asterick>}
				</StyledLabel>
			)}
			<AddressInput options={suggestions} {...props} {...{address, setAddress, isStandardizedAddress, setIsStandardizedAddress, selectedOption, setSelectedOption, clickedAddress}} />
		</Container>
    );
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export default StandardizedAddressInput;