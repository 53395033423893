import axios from "axios";
import SafeArea from "components/safearea";
import { format } from "date-fns";
import * as React from "react";
import { useQuery } from "react-query";
import { TPayment } from "types/payment";
import { dollarFormatter } from "utils";
import { useTranslation } from 'react-i18next';

export default function Payments() {
  const { t } = useTranslation();
  const tBase = "pages.protected.payments.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const query = useQuery<TPayment[] | null>(
    ["payments"],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_API}/api/v1/consumer/payments
  `
        )
        .then((res) => res.data)
  );

  const populatePayments = query.data?.map((payment) => {
    return (
      <div
        key={payment.id}
        className="border-solid border-slate-400 border p-2 my-4 rounded flex flex-col items-start"
      >
        <div>
          <span className="text-xs font-bold text-slate-500">
            {`#${payment.id} • ${format(
              new Date(payment.entry_date),
              "MMM dd, yyyy"
            )}`}
          </span>
        </div>
        <h2>
          {payment.company_name}
          <span className="text-xs bg-slate-200 p-1 rounded ml-2">
            {payment.last4 ? tr("Card") : payment.payment_method}
          </span>
        </h2>
        <div className="text-3xl text-green-700">
          {dollarFormatter(payment.amount)}
        </div>
      </div>
    );
  }) || <span>{tr("Empty")}</span>;

  return (
    <SafeArea>
      <div className="p-2">
        <div>
          <h1>{tr("Payments")}</h1>
        </div>
        <div>{populatePayments}</div>
      </div>
    </SafeArea>
  );
}
