import { Route, Switch, Redirect } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
// import Search from "../unprotected/search";
import { useCallback } from "react";
import Appointments from "./appointments";
import Payments from "./payments";
import Invoices from "./invoices";
import { useTranslation } from 'react-i18next';

export default function Protected() {
  const { isAuth, isLoading } = useAuth();
  const ProtectedRoute = useCallback(
    ({ component: Component, ...rest }) => {
      return (
        <Route
          {...rest}
          render={(props) => {
            return isLoading ? (
              <Loading />
            ) : isAuth ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: "/signin",
                  state: { from: props.location.pathname },
                }}
              />
            );
          }}
        />
      );
    },
    [isAuth, isLoading]
  );
  return (
    <Switch>
      {/* <ProtectedRoute path="/questions/:id" exact component={Questions} />
      <ProtectedRoute path="/confirmation" exact component={Confirmation} /> */}
      {/* <ProtectedRoute exact path="/" component={Search} /> */}
      <ProtectedRoute exact path="/appointments" component={Appointments} />
      <ProtectedRoute exact path="/payments" component={Payments} />
      <ProtectedRoute exact path="/invoices" component={Invoices} />
    </Switch>
  );
}

// const NoEntry = () => {
//   return <div>DENIED ENTRY</div>;
// };

const Loading = () => {
  const { t } = useTranslation();
  const tBase = "pages.protected.index";
  const tr = (key) => t(`${tBase}.${key}`);

  return <div>{tr("is Loading")}</div>;
};
