import React, { useEffect, useState } from "react";

import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { CheckoutForm } from "./checkout-form";
import { useTranslation } from 'react-i18next';

interface IProps {
  invoiceId: string;
  companyStripeAccountId: string;
  customAmount?: number | null;
  amountDue?: number | null;
}

const PaymentForm = ({ invoiceId, companyStripeAccountId, customAmount, amountDue }: IProps) => {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.invoice.payment-form.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [stripePromise, setStripePromise] = useState<null | Promise<any>>(null);
  const [clientSecret, setClientSecret] = useState("");
  const [isPayable, setIsPayable] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const publishableKey = process.env.REACT_APP_STRIPE;
    if (publishableKey)
      setStripePromise(
        loadStripe(process.env.REACT_APP_STRIPE!, {
          stripeAccount: companyStripeAccountId,
        })
      );
  }, [companyStripeAccountId]);

  const getPaymentIntent = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/consumer/invoices/${invoiceId}/payment-intent-direct`,
        {
          params: {
            customAmount: customAmount ? customAmount : null,
            amountDue: amountDue ? amountDue : null,
          }
        }
      );

      setClientSecret(response.data.secret);
      setIsPayable(response.data.payable);
    } catch (error) {
      // @ts-ignore
      if (error.response.status === 405) {
        setIsPayable(false);
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPaymentIntent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  return (
    <>
      {loading ? (
        <div>{tr("loading...")}</div>
      ) : !isPayable ? (
        <div>{tr("Invoice Payment Disabled")}</div>
      ) : (
        <>
          {clientSecret && stripePromise && (
            // @ts-ignore
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <CheckoutForm customAmount={customAmount} />
            </Elements>
          )}
        </>
      )}
    </>
  );
};

export default PaymentForm;
