import SafeArea from "components/safearea";
import * as React from "react";
import styled from "styled-components";
import FB from "images/fb.jpg";
import Google from "images/google.jpg";
import Insta from "images/insta.jpg";
import Twitter from "images/twitter.jpg";
import { H3, P, ImageCon } from "../../style";
import { Button } from "components/button";
import ContactSales from "../../components/contactSalesButton";
import { useTranslation } from 'react-i18next';

export default function LearnMoreSection() {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.home.container.section.index";
  const tr = (key) => t(`${tBase}.${key}`);

  return (
    <Con>
      <SSafeArea padding flex="row">
        <SImageCon>
          <div>
            <SMImage src={FB} top="-30%" left="16%" />
            <SMImage src={Google} top="50%" left="51%" />
            <SMImage src={Insta} top="-10%" left="69%" />
            <SMImage src={Twitter} top="20%" left="-5%" />
          </div>
        </SImageCon>
        <div>
          <H3>{tr("Integrate with your favorite platforms")}</H3>
          <P>
            {tr("Simply paste your custom EServ link provided to any where and we will handle all the incoming requests.")}
          </P>
          <ContactSales />
        </div>
      </SSafeArea>
    </Con>
  );
}

const SImageCon = styled(ImageCon)`
  background: #3949ab;
  position: relative;
  width: 100%;
  height: auto;
  > div {
    padding-bottom: 75%;
  }
`;

const SMImage = styled.img`
  font-weight: bold;
  color: #c5cae9;
  font-size: 7rem;
  letter-spacing: -5px;
  position: absolute;
  width: 32%;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  transform: translate(-50%, -50%);
  transform: rotate(-30deg);
`;

const Con = styled.div`
  display: flex;
  margin: 2rem 0;
`;

const SSafeArea = styled(SafeArea)`
  display: flex;
  justify-content: center;
  align-items: center;
  > div:last-child {
    flex: 0 0 40%;
    margin: 1rem;
  }
  @media screen and (max-width: 1300px) {
    flex-direction: column;
    > div:nth-child(2) {
      margin: 0;
    }
  }
`;
