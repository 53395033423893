import { useHistory } from "react-router-dom";
import styled from "styled-components";
import MSImage from "../../unprotected/business/placeholderpictures/pic1.png";
import { useQuery } from "react-query";
import axios from "axios";
import JoinWaitlist from "./container/waitlist";
import SafeArea from "components/safearea";
import LearnMoreSection from "./container/section";
import QuestionBuilderSection from "./container/section/questionBuilder";
import ManageRequestSection from "./container/section/manageRequest";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  display: flex;
  flex-direction: column;
`;

const fetchCompaniesQuery = async () =>
  await axios
    .get(
      `
${process.env.REACT_APP_API}/api/v1/consumer/companies
`
    )
    .then((res) => res.data);

export default function Search() {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.home.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const fetchCompanies = useQuery("companies", fetchCompaniesQuery);

  const populateCompanies =
    fetchCompanies &&
    fetchCompanies.data?.map((company) => (
      <Company key={company.id} data={company} />
    ));

  return (
    <Con>
      {/* <JoinWaitlist /> */}
      <CategoryCon flex="column">
        <h2 style={{ marginLeft: "1rem", flex: "0 0 auto" }}>
          {tr("Popular Near You")}
        </h2>
        <CategoryDetails>{populateCompanies}</CategoryDetails>
      </CategoryCon>
      <div style={{ background: "#FFF0ED", padding: "2rem 0" }}>
        <SafeArea padding>
          <h3
            style={{
              margin: 0,
              padding: 0,
              fontWeight: "900",
              color: "#ff4f00",
              letterSpacing: "-1px",
            }}
          >
            {tr("Provide Services with EServ")}
          </h3>
        </SafeArea>
        <QuestionBuilderSection />
        <LearnMoreSection />
        <ManageRequestSection />
      </div>
    </Con>
  );
}

const CategoryDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  > div {
    margin: 1rem;
  }
`;
const CategoryCon = styled(SafeArea)`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
`;

const Company = ({ data }) => {
  const history = useHistory();

  return (
    <CompanyCon onClick={() => history.push(`/questions/${data.id}`)}>
      <ImageCon>
        {data.name}
        {/* <img
          alt={data.name}
          src={MSImage}
          style={{ width: "100%", minWidth: "300px", height: "auto" }}
        /> */}
      </ImageCon>
      <CompanyLabel>{data.name}</CompanyLabel>
      <CompanyRating>{data.description}</CompanyRating>
    </CompanyCon>
  );
};

const ImageCon = styled.div`
  overflow: hidden;
  border-radius: 10px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1rem;
  background: linear-gradient(
    164deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(102, 0, 138, 1) 50%,
    rgba(230, 71, 0, 1) 100%
  );
  color: white;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 2rem;
  transition: all 0.1s linear;
  border: 3px solid white;
  &:hover {
    border: 3px solid #c0c0c0;
    transform: scale(1.03);
  }
`;

const CompanyLabel = styled.div`
  font-weight: 600;
  text-transform: capitalize;
`;

const CompanyRating = styled.div``;

const CompanyCon = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;
