import styled from "styled-components";
import SafeArea from "components/safearea";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
	margin-top: auto;
	background: #191919;
	display: flex;
	color: white;
	font-size: 0.9rem;
`;

const SSafeArea = styled(SafeArea)`
	display: flex;
	flex-direction: column;
	> div:first-child {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 1rem;
		justify-content: space-between;
		> div {
			&:not(:last-child) {
				margin-right: 3rem;
			}
		}
	}
`;

const Row = styled.div``;

const Column = styled.div`
	display: flex;
	flex-direction: column;
`;

const A = styled.a`
	color: white;
	text-decoration: none;
	&:hover {
		color: #c0c0c0;
		text-decoration: underline;
	}
`;

const Content = styled.div`
	display: flex;
	flex-wrap: wrap;
	color: #f0f0f0;
	justify-content: space-between;
	> div {
		display: flex;
		flex-direction: column;

		> *:not(:last-child) {
			margin-bottom: 0.5rem;
		}

		&:not(:last-child) {
			margin-right: 2rem;
		}
	}
`;

export default function () {
	const { t } = useTranslation();
	const tBase = "components.footer";
	const tr = (key) => t(`${tBase}.${key}`);

	return (
		<Con>
			<SSafeArea padding>
				<div>
					<div>
						<h2>{tr("Top services provided")}</h2>
						<Content>
							<div>
								<span>{tr("Irrigation Services")}</span>
								<span>{tr("Electrician")}</span>
								<span>{tr("Landscaping")}</span>
								<span>{tr("Roofing")}</span>
								<span>{tr("Appliance Repair")}</span>
								<span>{tr("Installation Services")}</span>
								<span>{tr("Janitorial Services")}</span>
								<span>{tr("Pool & Spa Services")}</span>
								<span>{tr("Pressure Washing")}</span>
								<span>{tr("Property Maintenance")}</span>
							</div>
							<div>
								<span>{tr("Carpet Cleaning")}</span>
								<span>{tr("Chimney Sweep Services")}</span>
								<span>{tr("Commercial Cleaning")}</span>
								<span>{tr("Dog Walking")}</span>
								<span>{tr("Elevator Services")}</span>
								<span>{tr("Junk Removal")}</span>
								<span>{tr("Lawn Care")}</span>
								<span>{tr("Locksmith Services")}</span>
								<span>{tr("Restoration")}</span>
								<span>{tr("Snow Removal")}</span>
							</div>
							<div>
								<span>{tr("Fence Services")}</span>
								<span>{tr("Flooring")}</span>
								<span>{tr("Garage Door Services")}</span>
								<span>{tr("General Contracting")}</span>
								<span>{tr("Handyman")}</span>
								<span>HVAC</span>
								<span>{tr("Painting")}</span>
								<span>{tr("Plumbing")}</span>
								<span>{tr("Tree Care")}</span>
								<span>{tr("Window Cleaning")}</span>
							</div>
						</Content>
					</div>
					<div>
						<h2>{tr("Top cities")}</h2>
						<Content>
							<div>
								<span>Omaha, NE</span>
								<span>Lincoln, NE</span>
							</div>
						</Content>
					</div>
					<div>
						<h2>{tr("Get to know Us")}</h2>
						<Content>
							<div>
								<A
									href={`
              mailto:info@eserv.com?subject=Inquiry
              &body=
              `}
								>
									{tr("Contact Us")}
								</A>
								<A
									href={`https://sales.eserv.com`}
									target="blank"
								>
									{tr("Learn More")}
								</A>
							</div>
						</Content>
					</div>
				</div>
				<div>
					<span>&#169; 2021 Eserv. {tr("All rights reserved")}.</span>
				</div>
			</SSafeArea>
		</Con>
	);
}
