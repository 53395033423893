import * as React from "react";
import styled, { css } from "styled-components";

export default function SafeArea(props) {
  const { children, ...rest } = props;
  return <Con {...rest}>{children}</Con>;
}

const colFlex = css`
  align-self: center;
`;

const rowFlex = css`
  margin: 0 auto;
`;

const Con = styled.div`
  width: 100%;
  padding: ${(props) => (props.padding ? "1rem" : "0")};
  max-width: 1300px;
  ${(props) => (props.flex === "column" ? colFlex : rowFlex)}
`;
