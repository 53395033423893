import * as React from "react";
import styled from "styled-components";
import JoinWaitListVideo from "components/videos/joinwaitlist.mp4";
import { RiArrowRightLine } from "react-icons/ri";
import SafeArea from "components/safearea";
import { Button } from "components/button";
import { useModal } from "contexts/modalContext";
import WaitListModal from "./waitListModal";
import { useTranslation } from 'react-i18next';

export default function JoinWaitlist() {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.home.container.waitlist.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const { setShow } = useModal();
  return (
    <Con>
      <video playsInline loop autoPlay muted>
        <source src={JoinWaitListVideo} type="video/mp4" />
      </video>
      <BackgroundTint />
      <SSafeArea padding>
        <h1>{tr("Become a Partner")}</h1>
        <div>
          <span>
            {tr("Interested in growing your business? Sign up now to get early access to EServ!")}
          </span>
        </div>
        <div>
          <Button
            primary
            onClick={
              () => (window.location.href = "https://sales.eserv.com")
              // setShow({ label: "Sign Up", component: <WaitListModal /> })
            }
          >
            {tr("Find Out More")}
          </Button>
        </div>
      </SSafeArea>
    </Con>
  );
}

const BackgroundTint = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
`;

const Con = styled.div`
  margin-bottom: 1rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
  > video {
    top: 0;
    position: absolute;
    width: 100%;
    height: auto;
    z-index: -2;
    @media only screen and (max-width: 800px) {
      height: 100%;
      width: auto;
    }
  }
`;

const SSafeArea = styled(SafeArea)`
  font-size: 1.5rem;
  color: white;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > div {
    width: 100%;
    max-width: 600px;
    > button {
      margin-top: 1rem;
      border-radius: 25px;
      align-self: flex-start;
    }
  }
`;
