import { cloneDeep } from "lodash";

export const handleInit = ({ ...state }, action, { rawQuestions, rawLink }) => {
  let { id, questions, link: rawLinkss, business } = action.payload;
  rawQuestions.current = questions;
  rawLink.current = rawLinkss;

  let formatQuestions = rawQuestions.current
    ?.find((category) => category.is_primary)
    ?.questions?.map((data) => ({
      ...data,
      response: [],
    }));

  return {
    id: id,
    currentQuestionIndex: 0,
    link: null,
    reachedSummary: false,
    questions: formatQuestions,
    business: business,
    refetch: false,
  };
};
export const handleLinkUpdate = (
  { questions, link, ...state },
  rawQuestions
) => {
  let res = cloneDeep(questions);
  res = res.filter((answer) => {
    return answer.is_primary;
  });

  let newQuestions = rawQuestions.current
    ?.find((category) => category.fk_category_id === link)
    ?.questions?.map((question) => ({ ...question, response: [] }));

  if (newQuestions) {
    return {
      ...state,
      reachedSummary: false,
      link,
      questions: [...res, ...newQuestions],
    };
  }
  return {
    ...state,
    link,
    questions: res,
  };
};

export const handleNextClick = (
  { questions, currentQuestionIndex, ...state },
  type
) => {
  let nextIndex;
  if (type === "TOEND") {
    nextIndex = questions.length;
  } else {
    nextIndex = currentQuestionIndex + 1;
  }

  if (nextIndex === questions.length) {
    return {
      ...state,
      questions,
      reachedSummary: true,
      currentQuestionIndex: nextIndex,
    };
  }
  return {
    ...state,
    questions,
    currentQuestionIndex: nextIndex,
  };
};

export const handleBackClick = (
  { currentQuestionIndex, ...state },
  jumpToIndex
) => {
  if (jumpToIndex !== undefined) {
    return {
      ...state,
      currentQuestionIndex: jumpToIndex,
    };
  }
  return {
    ...state,
    currentQuestionIndex: currentQuestionIndex - 1,
  };
};

const handleMultipleSelectedOption = (answer, e) => {
  let { response, type } = answer;
  let index = response?.findIndex(({ id }) => id === e.id);

  if (index === undefined) {
    response = [e];
  } else if (index === -1) {
    if (type === "IMAGE" && response.length > 3) {
      response.splice(0, 1);
    }
    response.push(e);
  } else {
    response.splice(index, 1);
  }
};

export const handleSelectOption = (
  { questions, currentQuestionIndex, ...state },
  { e, id },
  rawLink
) => {
  const linkChanged =
    Array.isArray(rawLink.current) &&
    id === +rawLink.current[0]?.fk_question_id &&
    e;

  let tempAnswers = cloneDeep(questions);
  let link;
  if (linkChanged) {
    let categoryId = rawLink.current?.find(
      (link) => parseInt(link.fk_option_id) === e.id
    )?.fk_category_id;
    link = categoryId;
  }

  let answer = tempAnswers[currentQuestionIndex];

  let type = answer.type;

  const res = {
    ...state,
    link: linkChanged ? link : state.link,
    currentQuestionIndex,
  };

  if (answer) {
    switch (type) {
      case "MULTIPLE":
        handleMultipleSelectedOption(answer, e);
        return {
          ...res,
          questions: tempAnswers,
        };
      case "IMAGE":
        handleMultipleSelectedOption(answer, e);
        return {
          ...res,
          questions: tempAnswers,
        };
      default:
        answer.response = e ? [e] : [];

        return {
          ...res,
          skip: e.fk_subquestion_id ? false : true,
          questions: tempAnswers,
        };
    }
  }
};

export const handleSelectSubquestionOption = (state, action) => {
  let questions = cloneDeep(state).questions;

  const { subquestionId, subquestionType, e, optionId } = action;

  // find response questionIdx

  const response = questions[state.currentQuestionIndex].response;

  // find response optionIdx

  const subquestions = response.find((res) => res.id === optionId).subquestions;

  // find response subquestionIdx

  let answerIdx = subquestions.findIndex(
    (subques) => subques.id === subquestionId
  );

  let answer = subquestions[answerIdx];

  answer.response = answer.response || [];

  switch (subquestionType) {
    case "MULTIPLE":
      handleMultipleSelectedOption(answer, e);
      return {
        ...state,
        questions,
      };
    case "IMAGE":
      handleMultipleSelectedOption(answer, e);
      return {
        ...state,
        questions,
      };
    default:
      answer.response = e ? [e] : [];

      return {
        ...state,
        skip: false,
        questions,
      };
  }
};
