import styled from "styled-components"
import { useTranslation } from 'react-i18next';

export default function Loading() {
    const { t } = useTranslation();
    const tBase = "pages..loading.index";
    const tr = (key) => t(`${tBase}.${key}`);

    return (
        <Con>
            {tr("Loading")}
        </Con>
    )
}

const Con = styled.div``