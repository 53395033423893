import styled from "styled-components";

export default function Avatar({ src, className }) {
  return (
    <Con className={className}>
      <img
        src={src}
        style={{
          borderRadius: "50%",
          width: "90%",
          height: "90%",
        }}
      />
    </Con>
  );
}

const Con = styled.div`
  background: white;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
