import Axios from "axios";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { IconButton, SubtleButton, Button } from "components/button";
import Loading from "pages/loading/index.js";
import Info from "./components/info";
import LoginPrompt from "./components/logInPrompt";
import { useAuth } from "contexts/authContext";
import ImagesQuestionAnswer from "./components/imagesQuestionAnswer";
import { MdEdit } from "react-icons/md";
import { useEffect, useState } from "react";
import { useRequest } from "pages/unprotected/question/requestContext";
import { useParams } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { format } from "date-fns";
import { mapValues } from "lodash";
import { useTranslation } from 'react-i18next';

export default function Summary({ setPrompt }) {
	const { t } = useTranslation();
	const tBase = "pages.unprotected.question.summary.index";
	const tr = (key) => t(`${tBase}.${key}`);

	const { isAuth } = useAuth();
	const params = useParams();
	const { state, dispatch } = useRequest();
	const [address, setAddress] = useState();
	const [reqid, setReqid] = useState();
	const [datePreference, setDatePreference] = useState(null);

	const fetchPersonalInfo = useQuery(
		"personalInfo",
		async () =>
			axios
				.get(`${process.env.REACT_APP_API}/api/v1/consumer/profile`)
				.then((res) => res.data),
		{
			retry: false,
			onSuccess: (data) => {
				if (data.addresses) {
					setAddress(data.addresses[0].id);
				}
			},
		}
	);

	const isValid =
		fetchPersonalInfo.data &&
		Object.entries(fetchPersonalInfo.data).findIndex(
			([key, value]) => !value
		) === -1;

	const {
		mutate: sendRequest,
		isLoading,
		isSuccess,
	} = useMutation(
		(answers) => {
			const formData = new FormData();

			let parseAnswer = answers.map((answer) => {
				let responses;

				if (answer.type === "IMAGE") {
					answer.response.forEach(({ option }) =>
						formData.append("images", option)
					);
					// responses = answer.response.map(({ option }) => {
					//   return option.name;
					// });
				} else {
					responses = answer.response;

					answer.response.forEach((res) => {
						if (res.subquestions) {
							res.subquestions.forEach((subq) => {
								if (subq.type === "IMAGE") {
									subq.response?.forEach(({ option }) =>
										formData.append("images", option)
									);
								}
							});
						}
					});

					// answer.response.map(({ option }) => option);
				}

				return {
					...answer,
					response: responses,
				};
			});

			formData.append("responses", JSON.stringify(parseAnswer));

			formData.append("link", state.link);

			formData.append("id", params.id);

			formData.append("address", address);

			formData.append("preferredDate", datePreference);

			return Axios.post(
				`${process.env.REACT_APP_API}/api/v1/requests`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			).then((res) => res.data);
		},
		{
			onSuccess: async (data) => {
				setReqid(data.id);
				dispatch({ type: "clearQuestion" });

				setPrompt({
					pathname: "/confirmation",
					state: {
						business: state.business,
						redirect_url: data.redirect_url,
					},
				});
			},
		}
	);

	useEffect(() => {
		state.questions.map((question) => {
			if (question.type === "CALENDAR") {
				setDatePreference(question.response[0].option)
			}
		})
	}, [])

	const populateQuestions = state.questions.map(
		({ id, question, response, type }, index) => {
			const populateSubquestions = (subquestions) => {
				return subquestions.map((subq) => {
					return (
						<div key={subq.id}>
							<span className="text-sm font-bold">
								{subq.question}
							</span>
							<div className="flex flex-col">
								{subq.type === "IMAGE" ? (
									<ImagesQuestionAnswer
										response={subq.response}
									/>
								) : subq.response?.length > 0 ? (
									subq.response.map((res) => (
										<span key={res.id}>{res.option}</span>
									))
								) : (
									tr("Not answered")
								)}
							</div>
						</div>
					);
				});
			};

			return (
				<Question key={id}>
					<QuestionIndex>{index + 1}. </QuestionIndex>
					<QuestionDetails>
						<QuestionLabel>{question}</QuestionLabel>
						{type === "IMAGE" ? (
							<ImagesQuestionAnswer {...{ response }} />
						) : (
							<QuestionAnswer>
								{response?.length > 0
									? response.map(({ id, option, ...res }) => {
											return (
												<div key={id}>
													<span key={id}>
														{" "}
														{option}
													</span>
													{res.subquestions && (
														<div className="pt-2 mt-2 space-y-2 border-t">
															{populateSubquestions(
																res.subquestions
															)}
														</div>
													)}
												</div>
											);
									  })
									: tr("Not answered")}
							</QuestionAnswer>
						)}
					</QuestionDetails>
					<JumpToQuestion>
						<IconButton
							onClick={() =>
								dispatch({ type: "back", to: index })
							}
						>
							<MdEdit />
						</IconButton>
					</JumpToQuestion>
				</Question>
			);
		}
	);

	// This logic has been moved to the API and creates appointments on every request submission
	// const createNewAppt = useMutation(
	// 	async (e) =>
	// 		await Axios.post(
	// 			`${process.env.REACT_APP_API}/api/v1/company/appointment/requests/${reqid}`,
	// 			e
	// 		).then((res) => res.data),
	// 	{
	// 		onSuccess: () => {
	// 			console.log("appointment made");
	// 		},
	// 	}
	// );

	// let appointment = {};

	// const initialValues = {
	// 	date: preferredDate,
	// 	start_time: appointment?.start_time
	// 		? format(new Date(appointment.start_time), "HH:mm")
	// 		: "",
	// 	end_time: appointment?.end_time
	// 		? format(new Date(appointment.end_time), "HH:mm")
	// 		: "",
	// 	duration: "",
	// 	// assignee: appointment?.assignee || [],
	// 	assignee: null,
	// 	note: appointment?.note || "",
	// 	estimate_amount: appointment?.estimate_amount / 100 || "",
	// 	completion_note: appointment?.completion_note || "",
	// 	type: appointment?.type || "job",
	// 	fk_appointment_type_id:
	// 		appointment?.fk_appointment_type_id?.toString() || "",
	// 	estimate_products: null,
	// 	estimate_pricetype: "sale",
	// 	is_reminding: appointment?.is_reminding || false,
	// };

	// const onSubmit = (e) => {
	// 	// console.log("submit");
	// 	const tempDate = e.date ? e.date : format(new Date(), "yyyy-MM-dd");
	// 	const formatData = mapValues(e, (value, key) => {
	// 		if (!value) return null;
	// 		if (key === "start_time" || key === "end_time") {
	// 			return format(new Date(tempDate + " " + value), "HH:mmXXX");
	// 		}
	// 		return value;
	// 	});
	// 	let formatParts = [];
	// 	let parentId = null;
	// 	let parentType = null;
	// 	let sendEmail = true;

	// 	createNewAppt.mutate({
	// 		...formatData,
	// 		parentId,
	// 		parentType,
	// 		parts: formatParts,
	// 		notify: sendEmail,
	// 		isBookit: true,
	// 	});
	// };

	// const validationSchema = Yup.object().shape({
	// 	date: Yup.date(),
	// 	start_time: Yup.mixed(),
	// 	end_time: Yup.mixed(),
	// 	duration: Yup.number(),
	// 	tech_assigned: Yup.array().of(Yup.number()),
	// 	note: Yup.string(),
	// 	// estimate_amount: Yup.number(),
	// 	completion_note: Yup.string(),
	// 	type: Yup.string(),
	// 	fk_appointment_type_id: Yup.string().nullable(),
	// 	is_reminding: Yup.boolean(),
	// });

	// const formik = useFormik({
	// 	validationSchema,
	// 	initialValues,
	// 	// onSubmit,
	// 	enableReinitialize: true,
	// });

	const handleSubmit = () => {
		sendRequest(state.questions);
	};

	const handleStartOver = () => {
		if (window.confirm(tr("Are you sure you want to start over?"))) {
			// setRefetch(true);
			dispatch({ type: "refetch" });
			// clearQuestion();
		}
	};

	return (
		<Con>
			{isAuth && (
				<CategoryCon>
					<CategoryTitle>{tr("Personal")}</CategoryTitle>
					{!fetchPersonalInfo.isLoading && (
						<Info
							data={fetchPersonalInfo.data}
							address={address}
							handleAddress={(id) => {
								setAddress(id);
							}}
						/>
					)}
				</CategoryCon>
			)}

			<CategoryCon>
				<CategoryTitle>{tr("Summary")}</CategoryTitle>
				<div>{populateQuestions}</div>
			</CategoryCon>
			{isAuth ? (
				<ButtonCon>
					<Button
						primary
						disabled={!isValid}
						isLoading={isLoading}
						style={{ marginTop: "3rem" }}
						onClick={() => handleSubmit()}
					>
						{tr("Submit")}
					</Button>
					<SubtleButton
						style={{ alignSelf: "center" }}
						onClick={() => dispatch({ type: "back" })}
					>
						{tr("Back")}
					</SubtleButton>
					<SubtleButton
						style={{ alignSelf: "center", color: "red" }}
						onClick={() => handleStartOver()}
					>
						{tr("Start Over")}
					</SubtleButton>
				</ButtonCon>
			) : (
				<LoginPrompt {...{ setPrompt }} />
			)}
		</Con>
	);
}

const ButtonCon = styled.div`
	display: flex;
	flex-direction: column;
`;

const CategoryCon = styled.div`
	&:nth-child(2) {
		border-top: 1px solid #aeb6bf;
	}
`;

const CategoryTitle = styled.h3`
	margin: 0.5rem 0 0.5rem 0;
	padding: 0;
`;

const Question = styled.div`
	display: flex;
	border-bottom: 1px solid #d4d4d4;
	align-items: flex-start;
	padding: 1rem 0;
`;

const JumpToQuestion = styled.div`
	margin-left: auto;
	flex: 0;
`;

const QuestionDetails = styled.div`
	flex: 1;
`;

const QuestionIndex = styled.div`
	font-weight: bold;
	flex: 0;
	align-self: flex-start;
`;

const Con = styled.div`
	display: flex;
	flex-direction: column;
`;

const QuestionLabel = styled.div`
	font-weight: bold;
`;

const QuestionAnswer = styled.div`
	margin-top: 0.2rem;
	display: flex;
	flex-direction: column;
`;
