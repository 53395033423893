import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Button } from "components/button";
import { MdCheckCircle } from "react-icons/md";
import * as React from "react";
import { useTranslation } from 'react-i18next';

export default function Confirmation() {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.confirmation.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const history = useHistory();
  const location = useLocation();
  const [remaining, setRemaining] = React.useState(3);

  const business = location?.state?.business;
  const redirect_url = location?.state?.redirect_url;

  if (!business) {
    history.push("/company");
  }

  React.useEffect(() => {
    if (redirect_url) {
      let redirectRemaining = setInterval(() => {
        setRemaining((s) => s - 1);
      }, 1000);
      let redirectTimeout = setTimeout(() => {
        window.location.href = redirect_url;
      }, 3000);
      return () => {
        clearTimeout(redirectTimeout);
        clearInterval(redirectRemaining);
      };
    }
  }, [redirect_url]);

  return (
    <Con className="space-y-4 pb-8">
      <MdCheckCircle style={{ color: "#ff4f00", fontSize: "5rem" }} />
      <h1 style={{ color: "#FF4F00" }}>{tr("Thanks for the submission!")}</h1>
      <h4>
        {tr("An email confirmation has been sent to you.")} {business} {tr("will be contacting you shortly after the request is reviewed!")}
      </h4>
      <Button onClick={() => history.push("/company")}>{tr("Back to Home")}</Button>
      {redirect_url && (
        <div>
          {tr("Redirecting to")} <span className="font-bold">{redirect_url}</span> in{" "}
          <span className="bg-[#ff4f00] text-white rounded-full px-2 text-sm font-bold">
            {remaining}
          </span>
        </div>
      )}
    </Con>
  );
}

const Con = styled.div`
  width: calc(100% - 4rem);
  max-width: 500px;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 2rem;
`;
