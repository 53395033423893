export const formatPhoneNumber = (value) => {
  let formattedValue = value;
  formattedValue = formattedValue.trim();
  if (formattedValue.length === 5) {
    // remove last number in the bracket
    formattedValue = formattedValue.replace(/(.{2})$/g, "");
  }
  // remove all chars except numbers
  formattedValue = formattedValue.replace(/\D/g, "").split("");

  // if total character more than 10
  if (formattedValue.length > 10) {
    formattedValue.length = 10;
  }

  // if character more than 2 put a bracket
  if (formattedValue.length > 2) {
    formattedValue.unshift("(");
    formattedValue.splice(4, 0, ") ");
  }

  // if character more than 8 put a dash
  if (formattedValue.length > 8) {
    formattedValue.splice(8, 0, "-");
  }
  return formattedValue.join("");
};
