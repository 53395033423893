import { Route, Switch } from "react-router-dom";
import Business from "./business";
import Contracts from "./contracts";
import Search from "./home";
import SignIn from "./signin";
import Question from "./question";
import Confirmation from "./confirmation";
import { ErrorPage } from "pages/error/index.tsx";
import Estimate from "./estimate";
import Invoice from "./invoice";
import Privacy from "./privacy";
import PasswordReset from "./passwordReset";
import EULA from "./eula";

export default function Unprotected() {
  return (
    <Switch>
      <Route exact path="/password/:type/:id?" component={PasswordReset} />
      <Route exact path="/signin" component={SignIn} />
      <Route exact path="/signup" component={SignIn} />
      <Route exact path="/business/:id" component={Business} />
      <Route exact path="/contracts/:string" component={Contracts} />
      <Route exact path="/questions/:id" component={Question} />
      <Route exact path="/confirmation" component={Confirmation} />
      <Route
        exact
        path="/"
        component={() => window.location.replace("https://sales.eserv.com")}
      />
      <Route exact path="/company" component={Search} />
      <Route exact path="/estimate/:id" component={Estimate} />
      <Route exact path="/invoices/:id" component={Invoice} />
      <Route exact path="/payment/:id" component={Invoice} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/eula" component={EULA} />
      {/* <Route exact path="*" component={ErrorPage} /> */}
    </Switch>
  );
}
