import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
  const { t } = useTranslation();
  const tBase = "pages..error.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <Con>
      <h1>404</h1>
      <h2>{tr("The page is either not available or has been moved.")}</h2>
      <Link to="/">{tr("Back to Home")}</Link>
    </Con>
  );
};

const Con = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  > h1 {
    color: red;
    font-size: 5rem;
    margin-bottom: 0;
  }
`;

export { ErrorPage };
