import { useEffect, useState } from "react";
import styled from "styled-components";
import { IconButton, SubtleIconButton } from "components/button";
import Summary from "./summary";
import Options from "./questionType/options";
import ImageUploadQuestion from "./questionType/imageUploadQuestion";
import TextQuestion from "./questionType/text";
import {
  BsArrowLeft,
  BsArrowRight,
  BsChevronDoubleRight,
} from "react-icons/bs";
import Axios from "axios";
import { useQuery } from "react-query";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useRequest } from "pages/unprotected/question/requestContext";

import { useEffectAfter } from "hooks/useEffectAfter";
import { ErrorPage } from "pages/error";
import DatePicker from "react-multi-date-picker";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  // background: #d6dbdf;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const fetchData = async (id) => {
  return await Axios.get(
    `${process.env.REACT_APP_API}/api/v1/consumer/questions/${id}`
  ).then((res) => res.data);
};

export default function Questions() {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.question.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const { state, dispatch } = useRequest();
  const [activatePrompt, setPrompt] = useState(false);
  const history = useHistory();
  const params = useParams();
  const [minDate, setMinDate] = useState(new Date(Date.now()));

  const [datePreference, setDatePreference] = useState({
    primaryDate: null,
  });

  const currentQuestion = state.questions[state.currentQuestionIndex];

  useEffect(() => {
    if (currentQuestion?.type === "CALENDAR") {
      let futureDate = new Date(Date.now());
      if (currentQuestion?.options && currentQuestion?.options[0]?.option) {
        futureDate.setDate(
          futureDate.getDate() + parseInt(currentQuestion?.options[0].option)
        );
        let newMinDate = new Date(futureDate.toLocaleDateString());

        setMinDate(newMinDate);
      }
    }
    // eslint-disable-next-line
  }, [currentQuestion]);

  const handlePrimaryDate = (date) => {
    if (date > new Date()) {
      setDatePreference({
        primaryDate: new Date(date).toLocaleDateString(),
      });
    } else {
      setDatePreference({
        primaryDate: null,
      });
    }
    // not used because it seems to fire before datePreference is set. To ensure date is properly set before executing, this has been moved inside a useEffect
    // dispatch({type: "selectOption", e: {id: "1", option: datePreference.primaryDate}, id: currentQuestion.id})
  };

  useEffect(() => {
    if (datePreference.primaryDate) {
      dispatch({
        type: "selectOption",
        e: { id: "1", option: datePreference.primaryDate },
        id: currentQuestion.id,
      });
    }
  }, [datePreference]);

  useEffectAfter(() => {
    if (state.refetch) {
      fetch.refetch();
    }
  }, [state.refetch]);

  const fetch = useQuery("questions", async () => await fetchData(params.id), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: false,
    onSuccess: (data) => {
      dispatch({
        type: "init",
        payload: data,
      });
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (state.questions.length === 0 || state.id !== params.id) {
      fetch.refetch();
    }
  }, []);

  useEffect(() => {
    if (activatePrompt) history.push(activatePrompt);
  }, [activatePrompt]);

  useEffect(() => {
    window.onbeforeunload = () => true;
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  if (fetch.isError) return <ErrorPage />;

  const selectedOption = currentQuestion?.response || [];

  const buttonEnabled =
    !currentQuestion?.required ||
    (selectedOption.length > 0 &&
      selectedOption.findIndex((selOpt) => {
        const noReqSubQ = selOpt?.subquestions
          ? selOpt?.subquestions?.findIndex(
              (subq) =>
                subq.required && (!subq.response || subq.response.length === 0)
            ) === -1 ?? true
          : currentQuestion.type === "CALENDAR"
          ? datePreference.primaryDate
            ? true
            : false
          : true;
        return !noReqSubQ;
      }) === -1);

  const populateOptions = () => {
    const { type, options, id } = currentQuestion;
    switch (type) {
      case "TEXT":
        return (
          <TextQuestion
            {...{ selectedOption }}
            setSelectedOption={(e) => dispatch({ type: "selectOption", e, id })}
          />
        );
      case "IMAGE":
        return (
          <ImageUploadQuestion
            {...{ selectedOption }}
            setSelectedOption={(e) => dispatch({ type: "selectOption", e, id })}
          />
        );
      case "CALENDAR":
        return (
          <div style={{ height: "10em" }}>
            <div
              style={{
                marginLeft: "10px",
                marginTop: "20px",
                marginBottom: "20px",
                boxShadow: "0 0 10px 0 #f0f0f0",
                borderRadius: "5px",
                padding: "0.5rem",
              }}
              className="py-2 px-6 border my-2 ml-10 mr-4 text-black animate-[wiggle_0.25s_ease-out]"
            >
              <DatePicker
                style={{
                  display: "inline-block",
                  marginRight: "15px",
                  width: "8em",
                }}
                size={1}
                value={datePreference.primaryDate}
                format={"MM/DD/YYYY"}
                onChange={handlePrimaryDate}
                minDate={minDate}
                // minDate={new Date(currentQuestion.options[0].option)}
              />
              <p style={{ display: "inline-block" }}>{tr("Select Date")}</p>
            </div>
          </div>
        );
      default:
        return (
          <Options
            {...{
              options,
              selectedOption,
              type,
            }}
            setSelectedOption={(e) => dispatch({ type: "selectOption", e, id })}
          />
        );
    }
  };

  // if (!params.id) {
  //   return <div>no id</div>;
  // }

  if (state.questions.length === 0) return <div>{tr("Waiting")}</div>;

  return (
    <Con>
      <Prompt
        when={!activatePrompt}
        message={tr("You have unsaved changes. Do you want to proceed?")}
      />
      <LoadingBar
        length={
          (state.currentQuestionIndex / state.questions.length) * 100 + "%"
        }
      />
      <h3 style={{ padding: "0 1rem", margin: "0.5rem 0" }}>
        {state.business}
      </h3>
      <QuestionCon>
        {state.currentQuestionIndex < state.questions.length ? (
          <>
            <div className="flex items-center space-x-2">
              <h2>
                {currentQuestion.question?.split("\n").map((str, idx) => (
                  <p key={idx} className="mb-2">
                    {str}
                  </p>
                ))}
              </h2>
              {currentQuestion.required && (
                <span className="text-white bg-red-600 p-1 text-[0.6rem] rounded font-bold uppercase !h-auto">
                  {tr("Required")}
                </span>
              )}
            </div>
            {populateOptions()}
            <ButtonCon>
              <SubtleIconButton
                size="xlarge"
                disabled={state.currentQuestionIndex === 0}
                onClick={() => dispatch({ type: "back" })}
              >
                <BsArrowLeft />
              </SubtleIconButton>
              <IconButton
                size="xlarge"
                primary
                disabled={!buttonEnabled}
                onClick={() => dispatch({ type: "next" })}
              >
                <BsArrowRight />
              </IconButton>
              {state.reachedSummary && (
                <ToSummary
                  primary
                  size="xlarge"
                  disabled={!buttonEnabled}
                  onClick={() => dispatch({ type: "next", to: "TOEND" })}
                >
                  <BsChevronDoubleRight />
                </ToSummary>
              )}
            </ButtonCon>
          </>
        ) : (
          <Summary {...{ setPrompt }} />
        )}
      </QuestionCon>
    </Con>
  );
}

const QuestionCon = styled.div`
  background: white;
  width: calc(100% - 2rem);
  max-width: 600px;
  flex: 1 0 600px;
  border-radius: 5px;
  padding: 0 1rem 2rem 1rem;
  align-self: center;
`;

const LoadingBar = styled.div`
  height: 5px;
  background: #f0f0f0;
  width: 100%;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    height: 5px;
    width: ${(props) => props.length};
    background: #ff4f00;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: width 0.2s ease-out;
  }
`;

// const QuestionLabel = styled.h2`
//   margin: 0.5rem 0;
//   padding: 0;
// `;

const ButtonCon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  > button {
    margin: 0 1rem;
  }
`;

const ToSummary = styled(IconButton)`
  position: absolute;
  right: 0;
`;
