import * as React from "react";
import TextArea from "components/textarea";
import { Button } from "components/button";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

export default function DeclineModal({ handleSubmit }) {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.estimate.declineModal";
  const tr = (key) => t(`${tBase}.${key}`);

  const [comment, setComment] = React.useState("");

  return (
    <Con>
      <label>{tr("Please provide a reason")} *</label>
      <TextArea
        minRows={5}
        autoFocus
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <div>
        <Button>{tr("Cancel")}</Button>
        <Button
          onClick={() => handleSubmit(comment)}
          primary
          disabled={!comment}
        >
          {tr("Submit")}
        </Button>
      </div>
    </Con>
  );
}

const Con = styled.div`
  max-width: 400px;
  > div:last-child {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
  }
`;
