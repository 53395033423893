import styled from "styled-components";
import { theme } from "./theme";

export const SSegment = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.border.light};
  flex-direction: column;
  padding: 1rem 0;
  width: 100%;
`;

export const SHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`;

export const SContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SFooter = styled(SHeader)`
  padding-top: 0.5rem;
`;
