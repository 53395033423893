import React, { useState, useEffect } from "react";
import { PaymentIntent } from "@stripe/stripe-js";
import Confetti from "react-confetti";
import { useTranslation } from 'react-i18next';

interface IProps {
  paymentIntent: PaymentIntent;
}

const PaymentConfirmation = ({ paymentIntent }: IProps) => {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.invoice.payment-form.payment-confirmation";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const statusRef: Record<string, { text: string, status: string, style: string }> = {
    "succeeded": {
      text: tr("Your payment has been successfully processed."),
      status: "Payment Succeeded",
      style: "inline-block bg-green-100 text-green-800 px-2 py-1 rounded-md" 
    },
    "processing": {
      text: tr("Your payment is in the process of being confirmed. Please note that payments with a bank account may take several days to be withdrawn from your account."),
      status: "Payment Pending",
      style: "inline-block bg-yellow-100 text-yellow-800 px-2 py-1 rounded-md" 
    },
    "requires_action": {
      text: tr("Please check your email for a link to verify your bank account. Once verified, your payment will be withdrawn from your account."),
      status: "Requires Action",
      style: "inline-block bg-yellow-100 text-yellow-800 px-2 py-1 rounded-md" 
    },
    "default": {
      text: tr("Thank you for your payment."),
      status: "Payment Submitted",
      style: "inline-block py-1" 
    }
  };

  const { status } = paymentIntent;
  const currentStatus = statusRef[status] || statusRef['default'];

  const [displayConfetti, setDisplayConfetti] = useState(false);
  const [recycleConfetti, setRecycleConfetti] = useState(false);

  useEffect(() => {
    if (status === "succeeded") {
      setDisplayConfetti(true);
      setRecycleConfetti(true);

      setTimeout(() => {
        setRecycleConfetti(false); 
      }, 3000); 
      setTimeout(() => {
        setDisplayConfetti(false); 
      }, 10000); 
    }
  }, [status]);

  return (
    <div>
      {displayConfetti && 
        <Confetti 
          width={window.innerWidth - 17} // 17px is the width of the scrollbar
          height={window.innerHeight}
          recycle={recycleConfetti}
        />
      }
      <div className="mb-3">{tr("Your payment has been submitted. You may now close this page.")}</div>
      <div className="font-semibold">
        {tr("Status")}: <span className={`mb-3 ${currentStatus.style}`}>{currentStatus.status}</span>
      </div>
      <div className="mb-3">
        <span className="italic">
          {currentStatus.text}
        </span>
      </div>
    </div>
  );
};

export { PaymentConfirmation };
