import * as GoogleLogin from "react-google-login";
import * as React from "react";
import { Button } from "components/button";
import { useMutation } from "react-query";
import axios from "axios";
import { useAuth } from "contexts/authContext";
import styled from "styled-components";
import { FcGoogle } from "react-icons/fc";
import { useTranslation } from 'react-i18next';

export default function GoogleSignIn({ signUp }) {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.signin.components.googleSignIn";
  const tr = (key) => t(`${tBase}.${key}`);

  const { login } = useAuth();
  const logInViaGoogle = useMutation(
    async (body) =>
      await axios.post(
        `
    ${process.env.REACT_APP_API}/googleSignIn
    `,
        body
      ),
    {
      onSuccess: () => login(),
    }
  );

  const responseGoogle = (res) => {
    if (!res.error) {
      logInViaGoogle.mutate({ profile: res.profileObj, tokenId: res.tokenId });
    }
  };

  return (
    <GoogleLogin.GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      render={(renderProps) => (
        <StyledButton
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >
          <FcGoogle />
          {tr("Sign")} {signUp ? tr("Up") : tr("In")} {tr("with Google")}
        </StyledButton>
      )}
      buttonText="Login"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={"single_host_origin"}
    />
  );
}

const StyledButton = styled(Button)`
  background: rgb(51, 103, 214);
  width: 100%;
  border-radius: 25px;
  color: white;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  > svg {
    background: white;
    font-size: 1.2rem;
    padding: 0.1em;
    border-radius: 50%;
  }
  &:hover {
    background: rgb(51, 103, 214);
    box-shadow: 0 5px 5px 0 #c0c0c0;
  }
`;
