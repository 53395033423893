import * as React from "react";
import styled from "styled-components";
import { AiOutlineCamera } from "react-icons/ai";
import { useTranslation } from 'react-i18next';

type UploadImage = {
  blob: File | null;
  dataUrl: string | null | ArrayBuffer;
};

type Props = {
  multiple?: boolean;
  Component?: JSX.Element;
  uploadImages: (filess: UploadImage) => void;
  crop?: number;
};

export default function ImageUploadButton({
  multiple,
  uploadImages,
  Component,
  crop,
}: Props) {
  const { t } = useTranslation();
  const tBase = "components.button";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const imageUploadRef = React.useRef<any>();
  const canvasRef = React.useRef<any>();

  const handleClick = () => {
    imageUploadRef.current.click();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let files = e.target.files;

    if (files) {
      Object.entries(files).forEach(([key, file]) => {
        const reader = new FileReader();

        reader.onloadend = async () => {
          let result = reader.result;
          if (crop && result !== null) {
            result = await cropImage(result, crop, canvasRef.current);
          }

          uploadImages({ blob: file, dataUrl: result });
        };

        reader.readAsDataURL(file);
      });
    }
  };

  return (
    <>
      <input
        onChange={handleChange}
        ref={imageUploadRef}
        type="file"
        multiple={multiple}
        style={{ display: "none" }}
        accept="image/*"
      />
      {Component ? (
        React.cloneElement(Component, { onClick: handleClick })
      ) : (
        <Button onClick={handleClick}>
          <AiOutlineCamera />
          {tr("Upload Images")}
        </Button>
      )}
      <canvas ref={canvasRef} style={{ display: "none" }} />
    </>
  );
}

function cropImage(
  url: string | ArrayBuffer,
  aspectRatio: number,
  outputImage: any
): any {
  const inputImage = new Image();
  const enc = new TextDecoder("utf-8");

  return new Promise((resolve) => {
    inputImage.onload = () => {
      const inputWidth = inputImage.naturalWidth;
      const inputHeight = inputImage.naturalHeight;
      const inputImageAspectRatio = inputWidth / inputHeight;
      let outputWidth = inputWidth;
      let outputHeight = inputHeight;

      if (inputImageAspectRatio > aspectRatio) {
        outputWidth = inputHeight * aspectRatio;
      } else if (inputImageAspectRatio < aspectRatio) {
        outputHeight = inputWidth / aspectRatio;
      }

      const outputX = (outputWidth - inputWidth) * 0.5;
      const outputY = (outputHeight - inputHeight) * 0.5;

      outputImage.width = outputWidth;
      outputImage.height = outputHeight;
      const ctx = outputImage.getContext("2d");
      ctx.drawImage(inputImage, outputX, outputY);

      function getCanvasBlob(canvas: any) {
        return new Promise(function (resolve, reject) {
          canvas.toBlob(function (blob: any) {
            resolve(blob);
          });
        });
      }

      resolve(
        // blob: await getCanvasBlob(outputImage),
        outputImage.toDataURL("image/jpeg")
      );
    };

    if (typeof url === "string") {
      inputImage.src = url;
    } else {
      inputImage.src = enc.decode(url);
    }
  });
}

const Con = styled.div`
  display: flex;
  flex-direction: column;
`;

const Button = styled.button`
  background: transparent;
  // font-size: rem;
  border-radius: 3px;
  cursor: pointer;
  > svg {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  border: 1px solid grey;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
`;
