import * as React from "react";
import styled, { keyframes } from "styled-components";
import { RiLoader5Line } from "react-icons/ri";

type Props = {
  size?: number;
};
export default function Spinner({ size }: Props) {
  return <SpinnerCon {...{ size }} />;
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const SpinnerCon = styled(RiLoader5Line)<{ size?: number }>`
  animation: ${rotate} 0.5s linear infinite;
  font-size: ${(props) => props.size || 16}px;
`;
