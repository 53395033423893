import styled from "styled-components";
import { Drawer } from "components/navbar";
import FloatingButton from "components/button/floatingButton";
import Navbar from "components/navbar";
import Footer from "components/footer";
import { ReactQueryDevtools } from "react-query-devtools";
import { useState } from "react";
import Modal from "components/modal";
import { useModal } from "contexts/modalContext";

const Con = styled.div`
  min-height: 100vh;
  // min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  // overflow: ${(props) => (props.show ? "hidden" : "auto")};
  overflow: hidden;
`;

export default function GlobalComponents({ children }) {
  const { show } = useModal();
  const [drawerStatus, setDrawerStatus] = useState(false);
  const handleDrawer = () => {
    setDrawerStatus((status) => !status);
  };

  return (
    <Con show={show}>
      <Drawer {...{ drawerStatus, handleDrawer }} />
      <Navbar {...{ handleDrawer }} />
      <div className="min-h-[400px]">{children}</div>
      <Footer />
      {/* <FloatingButton /> */}
      <Modal />
      {/* {process.env.NODE_ENV !== "production" && (
            <ReactQueryDevtools initialIsOpen />
          )} */}
    </Con>
  );
}
