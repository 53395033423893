import { useEffectAfter } from "hooks/useEffectAfter";
import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useReducer,
} from "react";

import {
  handleLinkUpdate,
  handleNextClick,
  handleBackClick,
  handleSelectOption,
  handleSelectSubquestionOption,
  handleInit,
} from "./utils";
import { debounce } from "lodash";

const RequestContext = createContext();

const initialState = {
  id: null,
  questions: [],
  currentQuestionIndex: 0,
  business: null,
  reachedSummary: false,
  link: null,
  refetch: false,
  skip: false,
};

function RequestProvider(props) {
  const rawQuestions = useRef();
  const rawLink = useRef();

  function reducer(state, action) {
    switch (action.type) {
      case "init":
        return handleInit(state, action, { rawLink, rawQuestions });
      case "refetch":
        return {
          ...initialState,
          refetch: true,
        };
      case "clearQuestion":
        return initialState;
      case "linkUpdated":
        return handleLinkUpdate(state, rawQuestions);
      case "next":
        return handleNextClick(state, action.to);
      case "back":
        return handleBackClick(state, action.to);
      case "selectOption":
        return handleSelectOption(state, action, rawLink);
      case "selectSubquestionOption":
        return handleSelectSubquestionOption(state, action);
      default:
        throw new Error("Unhandled action");
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "linkUpdated" });
  }, [state.link]);

  const debouncing = useRef(
    debounce(() => {
      dispatch({ type: "next" });
    }, 200)
  );

  useEffectAfter(() => {
    /**
     * if single option selected then skip
     */
    const currentQuestion = state.questions[state.currentQuestionIndex];

    if (
      currentQuestion &&
      currentQuestion.response?.length > 0 &&
      currentQuestion.type === "SINGLE" &&
      !state.reachedSummary &&
      state.skip
    ) {
      debouncing.current();
    }
  }, [state.questions, state.skip]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state.currentQuestionIndex]);

  return (
    <RequestContext.Provider value={{ state, dispatch }}>
      {props.children}
    </RequestContext.Provider>
  );
}

const useRequest = () => useContext(RequestContext);

export { RequestProvider, useRequest };
