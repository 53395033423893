import styled from "styled-components";
import Banner from "./placeholderpictures/pic1.png";
import Avatar from "components/avatar";
import { GrLocationPin } from "react-icons/gr";
import { GoGlobe } from "react-icons/go";
import { FaPhone } from "react-icons/fa";
import { MdEmail, MdDescription } from "react-icons/md";
import { Button } from "components/button";
import Pic1 from "./placeholderpictures/pic1.png";
import Pic2 from "./placeholderpictures/pic2.jpg";
import Pic3 from "./placeholderpictures/pic3.jpg";
import Pic4 from "./placeholderpictures/pic4.jpg";
import Pic5 from "./placeholderpictures/pic5.jpg";
import { useHistory } from "react-router-dom";
import { Subtitle } from "styles";
import { useTranslation } from 'react-i18next';

const Avatarr = styled(Avatar)`
  position: absolute;
  bottom: -50px;
  left: 1rem;
`;

export default function () {
  const history = useHistory();
  return (
    <Con>
      <Header>
        <div
          style={{
            position: "relative",
            alignSelf: "center",
            width: "100%",
            maxWidth: "900px",
          }}
        >
          <div
            style={{
              height: "300px",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <ImageBanner src={Banner} />
          </div>
          <Avatarr src={Banner} />
        </div>
      </Header>
      <Content>
        <Title {...{ history }} />
        <BusinessInfo />
        <ServiceOffered />
        <Images />
      </Content>
    </Con>
  );
}

const Title = ({ history }) => {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.business.index";
  const tr = (key) => t(`${tBase}.${key}`);

  return (
    <TitleCon>
      <h1 style={{ marginBottom: 0 }}>Millard Sprinkler</h1>
      <TitleDescription>
        <Subtitle>Lawn Sprinkler &#183; 4.3 reviews</Subtitle>
      </TitleDescription>
      <Button
        style={{ margin: "1rem 0" }}
        onClick={() => history.push("/questions/1")}
      >
        {tr("Send Request")}
      </Button>
    </TitleCon>
  );
};

const BusinessInfo = () => {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.business.index";
  const tr = (key) => t(`${tBase}.${key}`);

  return (
    <ContainerWrapper title={"Info"}>
      <InfoRow>
        <GrLocationPin />
        <div>13235 Chandler Rd # C, Omaha, NE 68138</div>
      </InfoRow>
      <InfoRow>
        <GoGlobe />
        <div>millardsprinkler.com</div>
      </InfoRow>
      <InfoRow>
        <FaPhone />
        <div>(402) 894-1199</div>
      </InfoRow>
      <InfoRow>
        <MdEmail />
        <div>frontdesk@millardsprinkler.com</div>
      </InfoRow>
      <InfoRow>
        <MdDescription />
        <div>
          {tr("Family owned since 1995 we have built our reputation by providing the best package of value in the lawn sprinkler business. Since then Millard Sprinkler has served more than 30,000 customers and is widely recognized for developing, engineering and deploying innovative irrigation solutions. Omaha Magazine has recognized Millard Sprinkler as Omaha's #1 lawn sprinkler company since the category was introduced.")}
        </div>
      </InfoRow>
    </ContainerWrapper>
  );
};

const ServiceOffered = () => {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.business.index";
  const tr = (key) => t(`${tBase}.${key}`);

  return (
    <ContainerWrapper title="Services">
      <EachService>
        <ServiceTitle>{tr("New System Installation")}</ServiceTitle>
        <ServiceDetail>
          {tr("At Millard Sprinkler we customize your sprinkler system to meet your needs. Our designers meet with you to determine what is most important to you for lawn irrigation, and design the system based around your input, your terrain, lot dimensions, water requirements, turf coverage, landscape areas, trees, etc. The installation of your sprinkler system will be performed by a trained, professional crew using the most advanced equipment to minimize the effect on your yard.")}
        </ServiceDetail>
      </EachService>
      <EachService>
        <ServiceTitle>{tr("Renovations / Rehabs / Additions")}</ServiceTitle>
        <ServiceDetail>
          {tr("Have Conditions in your yard changed? Sometimes an existing sprinkler system needs to be overhauled or expanded due to landscape, tree growth, fences, new pool, or a new deck. We can recommend modifications and complete the work in short order.")}
        </ServiceDetail>
      </EachService>
      <EachService>
        <ServiceTitle>{tr("Service / Repair")}</ServiceTitle>
        <ServiceDetail>
          {tr("Millard Sprinkler can handle any problem with your irrigation system. This include but are not limited to, Broken / leaking sprinkler heads, broken valves, backflow problems, broken pipes, cut wires and much more. Our techs are fully trained and certified to diagnose and resolve any issue in a timely manor to ensure your lawn and landscape stay healthy and green.")}
        </ServiceDetail>
      </EachService>
      <EachService>
        <ServiceTitle>{tr("System Turn Ons")} </ServiceTitle>
        <ServiceDetail>
          {tr("Need help getting your sprinkler up and going for the year? Millard Sprinkler will help you get it going and show you how to operate your system. While we are there we will do full inspection of the system, clean any clogged nozzles, make head and spray adjustment for the correct coverage, and even more to ensure your system is running at it's optimal performance.")}
        </ServiceDetail>
      </EachService>
      <EachService>
        <ServiceTitle>{tr("Winterization")}</ServiceTitle>
        <ServiceDetail>
          {tr("To avoid damage to your system, it's important to have your system winterized prior to winter freezing. Millard Sprinkler can help! Our winterization includes Shutting the system off, removal of water from every zone using a large trailer air compressor, turning the clock off and draining the backflow.")}
        </ServiceDetail>
      </EachService>
    </ContainerWrapper>
  );
};

const Images = () => {
  return (
    <ContainerWrapper title="Images">
      {/* <div
        style={{
          display: "grid",
          width: "100%",
          flex: "0 0 auto",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr)",
        }}
      > */}
      <Image src={Pic1} />
      <Image src={Pic2} />
      <Image src={Pic3} />
      <Image src={Pic4} />
      <Image src={Pic5} />
      {/* </div> */}
    </ContainerWrapper>
  );
};

const Image = styled.img`
  height: 300px;
`;

const EachService = styled.div``;

const ServiceTitle = styled.h3``;

const ServiceDetail = styled.div`
  line-height: 1.5rem;
`;

const ContainerWrapper = ({ title, children }) => {
  return (
    <ContainerCon>
      <h2>{title}</h2>
      <ContainerDetail>{children}</ContainerDetail>
    </ContainerCon>
  );
};

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  align-items: center;
  font-size: 1.1rem;
  > *:nth-child(2) {
    margin-left: 1rem;
  }
  > *:first-child {
    font-size: 1.5rem;
    flex: 0 0 auto;
  }
`;

const ContainerCon = styled.div`
  border: 1px solid #aeb6bf;
  padding: 0 1rem 1rem 1rem;
  border-radius: 10px;
  margin: 1rem 0;
`;

const ContainerDetail = styled.div``;

const Con = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleCon = styled.div`
  margin: 0 1rem;
`;

const TitleDescription = styled.div`
  display: flex;
  flex-direction: row;
`;

const Header = styled.div`
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2rem;
`;

const ImageBanner = styled.img`
  width: 100%;
  min-width: 500px;
  max-width: 900px;
  min-height: 300px;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  align-self: center;
  max-width: 900px;
`;
