import styled from "styled-components";
import { useAuth } from "contexts/authContext";
import { Button, SubtleButton } from "components/button";
import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import Field from "components/field";
import * as Yup from "yup";
import { useMutation } from "react-query";
import Axios from "axios";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import SignUp from "./signup";
import GoogleSignIn from "./components/googleSignIn";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  height: 100%;
`;

const SignInCon = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  > input {
    margin-bottom: 1rem;
  }
`;

const LoginCon = styled.div`
  width: calc(100% - 4rem);
  max-width: 500px;
  justify-content: center;
  align-self: center;
  padding: 2rem;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 0;
`;

export default function SignIn(props) {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.signin.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const { isAuth, login } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const redirectedPath = location?.state?.from;
  const isSignIn = props.location.pathname === "/signin";
  const attemptLogin = async ({ username, password }) => {
    return await Axios.post(`${process.env.REACT_APP_API}/signin`, {
      username,
      password,
    });
  };
  const [errorMessage, setErrorMessage] = useState(false);

  const setIsSignIn = () => {
    if (isSignIn) {
      history.push({ pathname: "/signup", state: location?.state });
    } else {
      history.push({ pathname: "/signin", state: location?.state });
    }
  };

  useEffect(() => {
    setErrorMessage(false);
  }, [isSignIn]);

  const res = useMutation(attemptLogin, {
    onSuccess: () => {
      login();
    },
    onError: (res) => {
      if (res?.response?.data?.error) {
        return setErrorMessage(res.response.data.error);
      }
      setErrorMessage("Error has occured.");
    },
  });

  const handleSubmit = (value) => {
    res.mutate(value);
  };

  if (isAuth) {
    return (
      <Redirect
        to={{ pathname: redirectedPath || "/", state: location?.state }}
      />
    );
  }

  return (
    <Con>
      <LoginCon>
        <Title>{tr("Sign")} {isSignIn ? tr("In") : tr("Up")} {tr("to")} EServ</Title>
        <NewUser
          {...{ isSignIn }}
          handleSignUpClick={() => setIsSignIn((status) => !status)}
        />

        {isSignIn && (
          <>
            <GoogleSignIn />
            <Formik
              initialValues={{ username: "", password: "" }}
              validationSchema={Yup.object({
                username: Yup.string().required("Required"),
                password: Yup.string().required("Required"),
              })}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => {
                return (
                  <SignInCon>
                    <Field
                      label={tr("Email")}
                      name={"username"}
                      type={"text"}
                      error={touched.username && errors.username}
                    />
                    <div>
                      <Field
                        label={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {tr("Password")}{" "}
                            <SubtleButton
                              tabIndex="-1"
                              type="button"
                              onClick={() => history.push("/password/forget")}
                              style={{ marginLeft: "auto" }}
                              size="small"
                            >
                              {tr("Forgot Password?")}
                            </SubtleButton>
                          </div>
                        }
                        name={"password"}
                        type={"password"}
                        error={touched.password && errors.password}
                      />
                    </div>

                    <Button
                      type="submit"
                      isLoading={res.isLoading}
                      primary
                      style={{
                        borderRadius: "25px",
                      }}
                    >
                      {tr("Sign In")}
                    </Button>
                  </SignInCon>
                );
              }}
            </Formik>
            <Divider />
            <ContinueAsGuest {...{ setIsSignIn }} />
          </>
        )}
        {!isSignIn && (
          <>
            <GoogleSignIn signUp />
            <SignUp {...{ setErrorMessage }} />
          </>
        )}
        {errorMessage && <ErrorCon>{errorMessage}</ErrorCon>}
      </LoginCon>
    </Con>
  );
}

const NewUser = ({ handleSignUpClick, isSignIn }) => {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.signin.index";
  const tr = (key) => t(`${tBase}.${key}`);

  return (
    <div
      style={{
        textAlign: "center",
        color: "grey",
        fontWeight: "600",
        fontSize: "1.1rem",
      }}
    >
      {isSignIn ? (
        <>
          {tr("New to EServ?")}{" "}
          <SubtleButton primary onClick={handleSignUpClick}>
            {tr("Sign Up")}
          </SubtleButton>
        </>
      ) : (
        <>
          {tr("Already have an account?")}{" "}
          <SubtleButton primary onClick={handleSignUpClick}>
            {tr("Sign In")}
          </SubtleButton>
        </>
      )}
    </div>
  );
};

const Divider = styled.div`
  width: 100%;
  height: 10px;
  position: relative;
  &:before {
    content: "or";
    left: calc(50% - 0.5rem);
    font-size: 0.8rem;
    position: absolute;
    background: white;
    padding: 0 0.5rem;
    color: #ff4f00;
    font-weight: bold;
    z-index: 1;
    transition: translateY(-50%);
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 1px;
    z-index: 0;
    width: 100%;
    background: #ff4f00;
  }
`;

const ContinueAsGuest = ({ setIsSignIn }) => {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.signin.index";
  const tr = (key) => t(`${tBase}.${key}`);

  return (
    <ContinueAsGuestCon>
      <Button style={{ fontSize: "0.9rem" }} onClick={setIsSignIn}>
        {tr("Sign Up")}
      </Button>
    </ContinueAsGuestCon>
  );
};

const ContinueAsGuestCon = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  max-width: 500px;
  padding: 2rem 0;
  justify-content: center;
`;

// const Question = ({ label, type }) => {
//   return (
//     <div style={{ display: "flex", flexDirection: "column" }}>
//       <Label>{label}</Label>
//       <Input {...{ type }} />
//     </div>
//   );
// };

const ErrorCon = styled.div`
  background: #ffcdd2;
  color: #b71c1c;
  border: 1px solid #b71c1c;
  padding: 0.5rem;
  font-weight: bold;
  font-size: 0.8rem;
  margin: 1rem 0 0 0;
  border-radius: 5px;
`;
