import styled from "styled-components";
import { useTranslation } from 'react-i18next';

export default function ImagesQuestionAnswer({ response }) {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.question.summary.components.imagesQuestionAnswer";
  const tr = (key) => t(`${tBase}.${key}`);

  const populateImages =
    response?.map(({ dataUrl, id }) => {
      return (
        <ImageCon key={id}>
          <img src={dataUrl} alt={id} />
        </ImageCon>
      );
    }) || tr("No picture.");
  return <Con>{populateImages}</Con>;
}

const Con = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.2rem;
`;

const ImageCon = styled.div`
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  > img {
    width: 100%;
    object-fit: contain;
    height: 100%;
  }
`;
