import * as React from "react";
import { StyledComponent } from "styled-components";
import {
  StyledButton,
  StyledA,
  StyledOutlineButton,
  StyledOutlineA,
  StyledIconButton,
  StyledIconA,
  StyledSubtleButton,
  StyledSubtleA,
  StyledSubtleIconButton,
  StyledSubtleIconA,
  StyledOutlineIconButton,
  StyledOutlineIconA,
  ButtonP,
  StyledMenuButton,
  StyledNoStyleButton,
  StyledNoStyleA,
} from "./style";
import Spinner from "components/spinner";
import { LinkProps } from "react-router-dom";
import { Size } from "types/theme";

type ExtraProps = {
  size?: Size;
  primary?: boolean;
  type?: string;
  children: React.ReactNode;
  disabled?: boolean;
  square?: boolean;
  rounded?: boolean;
  isLoading?: boolean;
};
type ButtonProps = React.HTMLAttributes<HTMLButtonElement> & ExtraProps;

type AProps = any;

const handleButtonWrapping = (
  Component: StyledComponent<"button", any, ButtonP, any>
) =>
  React.forwardRef(
    (props: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
      let { size, children, disabled, isLoading, onClick, ...rest } = props;

      const handleClick = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
      ) => {
        props.type !== "submit" && e.preventDefault();
        onClick && onClick(e);
      };

      const button = (
        <Component
          onClick={handleClick}
          {...rest}
          size={size ? size : "regular"}
          ref={ref}
          disabled={disabled || isLoading}
        >
          {isLoading && <Spinner />}
          {children}
        </Component>
      );

      return button;
    }
  );

const handleAWrapping = (
  Component: StyledComponent<
    <S = unknown>(
      props: LinkProps<S> & React.RefAttributes<HTMLAnchorElement>
    ) => React.ReactElement<
      any,
      string | React.JSXElementConstructor<any>
    > | null,
    any,
    ButtonP,
    never
  >
) =>
  React.forwardRef(
    (props: AProps, ref: React.ForwardedRef<HTMLAnchorElement>) => {
      let { size, children, disabled, isLoading, onClick, ...rest } = props;

      return (
        <Component
          size={size ? size : "regular"}
          disabled={disabled || isLoading}
          {...rest}
          ref={ref}
        >
          {children}
        </Component>
      );
    }
  );

export const IconButton = handleButtonWrapping(StyledIconButton);
export const IconA = handleAWrapping(StyledIconA);

export const SubtleButton = handleButtonWrapping(StyledSubtleButton);
export const SubtleA = handleAWrapping(StyledSubtleA);

export const Button = handleButtonWrapping(StyledButton);
export const A = handleAWrapping(StyledA);

export const OutlineButton = handleButtonWrapping(StyledOutlineButton);
export const OutlineA = handleAWrapping(StyledOutlineA);

export const SubtleIconButton = handleButtonWrapping(StyledSubtleIconButton);
export const SubtleIconA = handleAWrapping(StyledSubtleIconA);

export const OutlineIconButton = handleButtonWrapping(StyledOutlineIconButton);
export const OutlineIconA = handleAWrapping(StyledOutlineIconA);

export const MenuButton = handleButtonWrapping(StyledMenuButton);

export const NoStyleButton = handleButtonWrapping(StyledNoStyleButton);
export const NoStyleA = handleAWrapping(StyledNoStyleA);
