import { useModal } from "contexts/modalContext";
import Modal from "react-modal";
import styled from "styled-components";
import { IconButton } from "components/button";
import { GrClose } from "react-icons/gr";

export default function ModalComponent() {
  const { show, setShow } = useModal();

  const isOpen = show ? true : false;

  const closeModal = () => setShow();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setShow()}
      contentLabel="Example Modal"
      ariaHideApp={false}
      style={{
        content: {
          padding: "1rem",
          position: "relative",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          borderRadius: "20px",
          transition: "all 0.1s linear",
          // this is for the question summary add/edit address modal, may be dangerous to have here
          overflow: "visible",
        },
        overlay: {
          background: "rgba(0,0,0,0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "all 1s linear",
        },
      }}
    >
      <Header>
        <span>{show?.label}</span>
        <IconButton onClick={closeModal}>
          <GrClose />
        </IconButton>
      </Header>
      <Content>{show?.component}</Content>
    </Modal>
  );
}

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  > span {
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 1rem;
  }
`;

const Content = styled.div``;
