import { useEffect, useRef } from "react";

export const useEffectAfter = (effect, dependency) => {
  const initial = useRef(true);

  useEffect(() => {
    if (!initial.current) {
      effect();
    }
  }, dependency);

  useEffect(() => {
    initial.current = false;
  }, []);
};
