import { Field, ErrorMessage } from "formik";
import styled, { css } from "styled-components";

export default function FieldInput({
  label,
  name,
  type,
  error,
  placeholder,
  setFieldValue,
  onChange,
  addrValue,
}) {
  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
  };

  if (label && name && type) {
    return (
      <Con>
        <Label>{label}</Label>
        {
          addrValue ? 
            <Input {...{ name, type, error, placeholder, value: addrValue }} />
          :
          onChange ? (
            <Input {...{ name, type, error, placeholder, onChange }} />
          ) : (
            <Input {...{ name, type, error, placeholder }} />
          )
        }

        <ErrorMessage {...{ name }} render={(msg) => <Error>{msg}</Error>} />
      </Con>
    );
  }

  return null;
}

//error={errors && errors[name]}

const Con = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const Label = styled.label`
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
`;

const Input = styled(Field)`
  width: 100%;
  -webkit-appearance: none;
  ${(props) =>
    props.error &&
    css`
      border: 1px solid red;
    `}
`;

const Error = styled.div`
  color: red;
  font-size: 0.8rem;
  font-weight: bold;
`;
