import React from "react";
import styled from "styled-components";
import Axios from "axios";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { Button } from "components/button";
import StandardizedAddressInput from "components/standardizedAddressInput";
import { Input } from "components/input";
import { useTranslation } from 'react-i18next';

export default function AddressFields({ cancel }) {
  const { t } = useTranslation();
  const tBase = "containers.addressField";
  const tr = (key) => t(`${tBase}.${key}`);

  const queryClient = useQueryClient();

  const mutateProfile = async (value) => {
    value = {...value, isStandardizedAddress: addressObj.isStandardizedAddress};
    return await Axios.post(
      `${process.env.REACT_APP_API}/api/v1/consumer/profile`,
      {
        type: "ADDRESS",
        data: value,
      }
    );
  };

  const { mutate } = useMutation(mutateProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries("personalInfo");
      cancel();
    },
  });

  const { register, handleSubmit, formState: {errors}, setValue } = useForm({
    mode: "onChange",
    delayError: 5000
  });

  const onSubmit = (data) => {
    mutate(data);
  };

  const coords = navigator.geolocation.getCurrentPosition(
    (position) => {
      console.log("position: ", position)
    },
    (error) => {
      console.log("error: ", error)
    }
  )

  const longitude = coords?.coords?.longitude;
  const latitude = coords?.coords?.latitude;

  const [addressObj, setAddressObj] = React.useState({
    address: "",
    isStandardizedAddress: false,
    selectedOption: {
      description: "",
      city: "",
      state: "",
      zipcode: "",
    },
  });

  React.useEffect(() => {
    setValue("street_address", addressObj.address);
    setValue("city", addressObj.selectedOption.city);
    setValue("state", addressObj.selectedOption.state);
    setValue("zip_code", addressObj.selectedOption.zipcode);
  }, [addressObj])
  
  const updateAddressValue = (name, value) => {
    setAddressObj({
      ...addressObj,
      selectedOption: {
        ...addressObj.selectedOption,
        [name]: value,
      },
    });
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <StandardizedAddressInput
        label={tr("Address")}
        name="street_address"
        setAddressObj={setAddressObj}
        register={register}
        setValue={setValue}
        onKeyPress={handleKeyPress}
        longitude={longitude}
        latitude={latitude}
        required
      />
      <Row>
        <div>
          <Input
            label={tr("City")}
            type="text"
            name="city"
            placeholder="Lincoln"
            onKeyPress={handleKeyPress}
            value={addressObj.selectedOption.city}
            {...register("city", { 
              required: "City is required",
              onChange: (e) => {
                updateAddressValue("city", e.target.value);
                setValue("city", e.target.value);
              },
            })}
          />
          <div style={{position: "absolute", color:"red"}}>
            {errors.city && errors.city.message}
          </div>
        </div>
        <div>
          <Input
            label={tr("State")}
            type="text"
            name="state"
            placeholder="NE"
            onKeyPress={handleKeyPress}
            value={addressObj.selectedOption.state}
            {...register("state", { 
              required: "State is required", 
              minLength: { value: 2, message: tr("State must be TWO characters")},
              maxLength: { value: 2, message: tr("State must be TWO characters")},
              onChange: (e) => {
                updateAddressValue("state", e.target.value);
                setValue("state", e.target.value);
              },
            })}
          />
          <div style={{position: "absolute", color:"red"}}>
            {errors.state && errors.state.message}
          </div>
        </div>
        <div>
          <Input
            label={tr("Zip Code")}
            type="text"
            name="zip_code"
            placeholder="68508"
            onKeyPress={handleKeyPress}
            value={addressObj.selectedOption.zipcode}
            {...register("zip_code", { 
              required: "Zip Code is required",
              minLength: { value: 5, message: tr("Zip Code must be FIVE characters")},
              maxLength: { value: 5, message: tr("Zip Code must be FIVE characters")},
              onChange: (e) => {
                updateAddressValue("zipcode", e.target.value);
                setValue("zip_code", e.target.value);
              },
            })}
          />
          <div style={{position: "absolute", color:"red"}}>
            {errors.zip_code && errors.zip_code.message}
          </div>
        </div>
      </Row>
      <ButtonCon>
        <Button onClick={cancel}>{tr("Cancel")}</Button>
        <Button primary type="submit">
          {tr("Submit")}
        </Button>
      </ButtonCon>
    </Form>
  );
}

const Form = styled.form`
  /* Add your form styling here */
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 50px;
  > input:nth-child(2) {
    margin: 0 0.5rem;
  }
`;

const ButtonCon = styled.div`
  display: flex;
  justify-content: flex-end;
  > button:first-child {
    margin-right: 0.5rem;
  }
`;

async function mutateProfile(values) {
  return await Axios.post(`${process.env.REACT_APP_API}/api/v1/consumer/profile`, {
    type: "ADDRESS",
    data: values,
  });
}
