import { useEffect } from "react";

import { BrowserRouter as Router, withRouter } from "react-router-dom";

import Context from "./contexts";
import GlobalComponents from "./globalComponents";
import ProtectedRoute from "./pages/protected";
import UnprotectedRoute from "./pages/unprotected";

import ReactGA from "react-ga";

ReactGA.initialize("UA-187803124-1");

function App(props) {
  return (
    <Router>
      <Context>
        <GlobalComponents>
          <Routes {...props} />
        </GlobalComponents>
      </Context>
    </Router>
  );
}

const Routes = withRouter((props) => {
  useEffect(() => {
    const { pathname } = props.location;
    ReactGA.pageview(pathname);
  }, [props.location.pathname]);

  return (
    <>
      <UnprotectedRoute />
      <ProtectedRoute />
    </>
  );
});

export default App;
