import * as React from "react";
import {
  StyledTextArea,
  StyledInput,
  StyledSearchInput,
  StyledTransparentInput,
  StyledSemiTransparentInput,
  Con,
  Asterick,
  StyledLabel,
  Error,
  StyledCheckbox,
  InputCon,
  StyledRadio,
} from "./style";
import { BsSearch } from "react-icons/bs";
import { theme } from "styles/theme";
import Spinner from "components/spinner";
import { FormikProps } from "formik";
import { get } from "lodash";
import { useTranslation } from 'react-i18next';

export type Props = Omit<
  React.HTMLProps<HTMLInputElement>,
  "size" | "value"
> & {
  label?: string;
  error?: string;
  size?: string;
  horizontal?: boolean;
  type?: string;
  search?: boolean;
  value?: string | null | number;
  formatOn?: string;
  formatter?: (e: any) => any;
  isLoading?: boolean;
  name?: string;
  formik?: FormikProps<any>;
};

const handleWrapper = (Component: any) =>
  React.forwardRef(
    (props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
      let {
        label,
        search,
        type,
        horizontal,
        required,
        size,
        error,
        style,
        isLoading,
        formik,
        name,
        ...rest
      } = props;
      let input;
      const { t } = useTranslation();
      const tBase = "components.input";
      const tr = (key: string) => t(`${tBase}.${key}`);

      // @ts-ignore
      error =
        error ||
        (name && get(formik?.touched, name) && get(formik?.errors, name)) ||
        "";

      const value =
        props.value ??
        (name && get(formik?.values, name)) ??
        (formik && "") ??
        undefined;

      if (type === "checkbox") {
        input = <StyledCheckbox {...props} ref={ref} />;
      } else if (type === "radio") {
        input = <StyledRadio {...props} ref={ref} />;
      } else {
        input = (
          <Component
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            value={value}
            error={error}
            size={size || "regular"}
            ref={ref}
            {...props}
            style={{}}
          />
        );
      }

      const displayLabel = label && (
        <StyledLabel {...{ size }}>
          {label}
          {required && <Asterick>({tr("Required")})</Asterick>}
        </StyledLabel>
      );

      return (
        <Con horizontal={horizontal} style={style}>
          {displayLabel}
          <InputCon hasLogo={search}>
            {search && <BsSearch style={{ color: theme.color.subtext2 }} />}
            {input}
            {isLoading && <Spinner />}
          </InputCon>

          {error && <Error>{error}</Error>}
        </Con>
      );
    }
  );

export const Input = handleWrapper(StyledInput);

export const TextArea = handleWrapper(StyledTextArea);

export const SemiTransparentInput = handleWrapper(StyledSemiTransparentInput);

export const TransparentInput = handleWrapper(StyledTransparentInput);

export const SearchInput = handleWrapper(StyledSearchInput);
