import * as React from "react";
import { formatPhoneNumber } from "pages/unprotected/signin/utils";
import { Input } from "components/input";
import { Button, SubtleButton } from "components/button";
import * as yup from "yup";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { useTranslation } from 'react-i18next';

export default function PhoneNumberField({ cancel }) {
  const { t } = useTranslation();
  const tBase = "containers.phoneNumberField";
  const tr = (key) => t(`${tBase}.${key}`);

  const [value, setValue] = React.useState("");
  const queryClient = useQueryClient();

  const updatePhoneNumber = useMutation(
    async () =>
      await axios.post(
        `
  ${process.env.REACT_APP_API}/api/v1/consumer/profile/phoneNumber
  `,
        { value }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("personalInfo");
        cancel();
      },
    }
  );

  let schema = yup
    .string()
    .required("Phone number is required")
    .matches(/\([0-9]{3}\) [0-9]{3}-[0-9]{4}/, "Phone number is not valid");

  const valid = schema.isValidSync(value);

  const handleChange = (e) => {
    let { value } = e.target;

    let formattedValue = formatPhoneNumber(value);
    setValue(formattedValue);
  };

  const handleSubmit = () => {
    updatePhoneNumber.mutate();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };
  return (
    <Con>
      <Input
        size="large"
        value={value}
        name="phone_number"
        type="text"
        label={tr("Phone Number")}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />

      <div>
        <SubtleButton onClick={cancel}>{tr("Cancel")}</SubtleButton>
        <Button primary disabled={!valid} onClick={handleSubmit}>
          {tr("Set Phone Number")}
        </Button>
      </div>
    </Con>
  );
}

const Con = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  > div:last-child {
    display: flex;
    align-self: flex-end;
    gap: 1rem;
  }
`;
