import React, { useState } from "react";
import styled from "styled-components";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  HiOutlineOfficeBuilding,
  HiLogout,
  HiOutlineChartBar,
  HiOutlineCash,
  HiOutlineClipboard,
  HiOutlineCalendar,
} from "react-icons/hi";
import { GrClose } from "react-icons/gr";
import {
  IconButton,
  SubtleButton,
  Button,
  SubtleIconButton,
  A,
} from "./button";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import ESERV from "./eservlogo.png";

// Language selector
import usIcon from "images/lang-icons/us.png"; // icon by Icon.doit from flaticon.com
import spainIcon from "images/lang-icons/spain.png"; // icon by Icon.doit from flaticon.com
import { useTranslation } from "react-i18next";

const Con = styled.div`
  padding: 0 0.5rem;
  height: 50px;
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: ${(props) => (props.isHome ? "absolute" : "relative")};
  top: 0;
  width: 100%;
  z-index: 5;
`;

export default function Navbar({ handleDrawer }) {
  const { t, i18n } = useTranslation();
  const tBase = "components.navbar";
  const tr = (key) => t(`${tBase}.${key}`);
  const history = useHistory();
  const { isAuth, isLoading } = useAuth();
  const location = useLocation();
  const [showTooltip, setShowTooltip] = useState(false);
  const [showLangSelect, setShowLangSelect] = useState(false);

  if (isLoading) return null;

  const isHome = location?.pathname === "/";

  return (
    <Con isHome={isHome}>
      <SubtleIconButton
        style={{ color: isHome ? "white" : "black" }}
        onClick={handleDrawer}
        size="large"
      >
        <GiHamburgerMenu />
      </SubtleIconButton>
      <img
        alt="logo"
        src={ESERV}
        onClick={() => history.push("/company")}
        style={{
          height: "20px",
          alignSelf: "center",
          marginLeft: "1rem",
          cursor: "pointer",
        }}
      />

      <div className="ml-auto flex items-center">
        <div className="relative mr-2">
          <div className="relative">
            <div 
              className="w-10 h-10"
              onMouseOver={() => setShowTooltip(true)}
              onMouseOut={() => setShowTooltip(false)}
            >
              <button onClick={() => setShowLangSelect(true)}>
                {i18n.language && i18n.language === "en" ? (
                  <img alt="ESERV" src={usIcon} />
                ) : (
                  <img alt="ESERV" src={spainIcon} />
                )}
              </button>
            </div>
            {showTooltip && (
              <>
                <div className={`
                  absolute top-[8px] z-[9999] w-fit bg-gray-700 py-1 px-2 rounded-[5px] text-white text-xs
                  ${i18n.language === "en" ? "-left-[75px]" : "-left-[60px]"}
                  `}>
                  {tr("Language")}
                  <div className="absolute w-2 h-2 bg-gray-700 top-1/2 -right-[8px] transform -translate-y-1/2 -translate-x-1/2 rotate-45" />
                </div>
                
              </>
            )}
          </div>
          {showLangSelect && (
            <div className="
              absolute top-10 -left-[80px] w-[120px]
              flex flex-col divide-y text-sm font-bold border border-1 border-black rounded-[4px] shadow-lg'
            ">
              <button 
                className="flex items-center justify-between px-2 py-0.5 hover:bg-[#ebedef]"
                onClick={() => {
                  i18n.changeLanguage("en")
                  setShowLangSelect(false)
                }}
              >
                {tr("English")} <img alt="ESERV" src={usIcon} className="w-10 h-10 ml-2" />
              </button>
              <button 
                className="flex items-center justify-between px-2 py-0.5 hover:bg-[#ebedef]"
                onClick={() => {
                  i18n.changeLanguage("es")
                  setShowLangSelect(false)
                }}
              >
                {tr("Spanish")} <img alt="ESERV" src={spainIcon} className="w-10 h-10 ml-2" />
              </button>
            </div>
          )}

        </div>
        <Button
          onClick={() => (window.location.href = "https://sales.eserv.com")}
        >
          {tr("About Us")}
        </Button>
        {!isAuth && (
          <>
            <SubtleButton
              size="large"
              style={{
                marginRight: "1rem",
                borderRadius: "25px",
                marginLeft: "auto",
              }}
              onClick={() => history.push("/signin")}
            >
              {tr("Sign In")}
            </SubtleButton>
            <Button
              primary
              size="large"
              style={{
                borderRadius: "25px",
              }}
              onClick={() => history.push("/signup")}
            >
              {tr("Sign Up")}
            </Button>
          </>
        )}
      </div>
    </Con>
  );
}

export const Drawer = ({ drawerStatus, handleDrawer }) => {
  const { t } = useTranslation();
  const tBase = "components.navbar";
  const tr = (key) => t(`${tBase}.${key}`);

  const history = useHistory();
  const { isAuth, logout } = useAuth();

  const linkClicked = (path) => {
    history.push(path);
    handleDrawer();
  };

  return (
    <OverlayCon
      onClick={handleDrawer}
      style={{ zIndex: drawerStatus ? 10 : -10 }}
    >
      <DrawerCon
        onClick={(e) => {
          e.stopPropagation();
        }}
        {...{ drawerStatus }}
      >
        <IconButton
          onClick={handleDrawer}
          size="large"
          style={{ margin: "0.5rem", alignSelf: "flex-start" }}
        >
          <GrClose />
        </IconButton>
        <div onClick={() => linkClicked("/company")}>
          <HiOutlineOfficeBuilding />
          {tr("Business")}
        </div>
        {isAuth && (
          <>
            {/* <div onClick={() => linkClicked("/company")}>
              <HiOutlineChartBar />
              Requests
            </div> */}
            <div onClick={() => linkClicked("/appointments")}>
              <HiOutlineCalendar />
              {tr("Appointments")}
            </div>
            <div onClick={() => linkClicked("/invoices")}>
              <HiOutlineClipboard />
              {tr("Invoices")}
            </div>
            <div onClick={() => linkClicked("/payments")}>
              <HiOutlineCash />
              {tr("Payments")}
            </div>
          </>
        )}
        {isAuth && (
          <>
            <a
              onClick={() => linkClicked("/privacy")}
              style={{marginTop: "auto", cursor: "pointer", padding:"10px"}}
            >
              {tr("Privacy Policy")}
            </a>
            <div
              style={{ color: "red" }}
              onClick={() => {
                handleDrawer();
                logout();
              }}
            >
              <HiLogout />
              {tr("Sign out")}
            </div>
          </>
        )}
      </DrawerCon>
    </OverlayCon>
  );
};

const OverlayCon = styled.div`
  width: 100vw;
  height: 100%;

  position: fixed;
`;

const DrawerCon = styled.div`
  min-height: 100vh;
  // min-height: -webkit-fill-available;
  width: 100%;
  max-width: 300px;
  background: white;
  transition: all 0.1s ease-out;
  box-shadow: ${(props) => (props.drawerStatus ? "0 0 20px -5px grey" : "0")};
  transform: translateX(${(props) => (props.drawerStatus ? "0%" : "-100%")});
  display: flex;
  flex-direction: column;
  > div {
    padding: 1rem;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    &:hover {
      background: #f0f0f0;
    }
    > svg {
      margin-right: 1rem;
      font-size: 1.2rem;
    }
  }
`;
