import styled from "styled-components";

export const H3 = styled.h3`
  /* letter-spacing: -1px; */
  font-size: 3rem;
  line-height: 3rem;
  margin: 3rem 0 3rem 0;
  /* padding: 0; */
`;

export const P = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
`;

export const ImageCon = styled.div`
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
  width: 100%;
`;
