import { Button } from "components/button";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { theme } from "theme";
import { useTranslation } from 'react-i18next';

export default function LoginPrompt({ setPrompt }) {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.question.summary.components.logInPrompt";
  const tr = (key) => t(`${tBase}.${key}`);

  const params = useParams();

  const handleButtonClick = (pathname) => {
    setPrompt({
      pathname,
      state: { from: `/questions/${params.id}` },
    });
  };
  return (
    <Con>
      <h3>{tr("Please sign in or sign up for free to complete the request!")}</h3>
      <Button
        style={{ margin: "1rem 0" }}
        onClick={() => handleButtonClick("/signin")}
        primary
      >
        {tr("Sign In")}
      </Button>
      <Button onClick={() => handleButtonClick("/signup")}>{tr("New User")}</Button>
    </Con>
  );
}

const Con = styled.div`
  /* background: ${theme.bg.wash}; */
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  border-radius: 10px;
  /* padding: 1rem; */
  > h3 {
    margin: 0;
  }
  > div {
    margin-top: 0.5rem;
    > button {
      width: 45%;
    }
    display: flex;
    justify-content: space-between;
  }
`;
