import axios from "axios";
import SafeArea from "components/safearea";
import { format } from "date-fns";
import * as React from "react";
import { useQuery } from "react-query";
import { TAppt } from "types/appointment";
import { useTranslation } from 'react-i18next';

export default function Appointments() {
  const { t } = useTranslation();
  const tBase = "pages.protected.appointments.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const query = useQuery<TAppt[] | null>(
    ["appointments"],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_API}/api/v1/consumer/appointments
  `
        )
        .then((res) => res.data)
  );

  const populateAppointments = query.data?.map((appt) => {
    return (
      <div
        key={appt.id}
        className="border-solid border-slate-400 border p-2 my-4 rounded flex flex-col items-start"
      >
        <h2>{appt.company_name}</h2>
        <div>
          {appt.date ? (
            <span>{format(new Date(appt.date), "MMM dd, yyyy")}</span>
          ) : (
            <span className="text-red-600">{tr("No Date")}</span>
          )}
        </div>
        {!appt.start_time && !appt.end_time
          ? tr("Anytime")
          : `${
              appt.start_time
                ? format(new Date(appt.start_time), "hh:mm aa")
                : "∞"
            } - ${
              appt.end_time ? format(new Date(appt.end_time), "hh:mm aa") : "∞"
            }`}
        <div
          className={`py-1 px-2 text-xs rounded font-bold my-2`}
          style={{
            color: `rgb(${appt.status.color})`,
            background: `rgba(${appt.status.color},0.2)`,
          }}
        >
          {appt.status.label}
        </div>
        {appt.assignee && (
          <div className="">
            <span className="text-slate-600 text-sm font-bold">{tr("Assignees")}</span>
            <div>
              {appt.assignee.map((assignee) => assignee.label).join(", ")}
            </div>
          </div>
        )}
      </div>
    );
  }) || <span>{tr("Empty")}</span>;

  return (
    <SafeArea>
      <div className="p-2">
        <div>
          <h1>{tr("Appointments")}</h1>
        </div>
        <div>{populateAppointments}</div>
      </div>
    </SafeArea>
  );
}
