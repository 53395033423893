import React from "react";

import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Button } from "components/button";
import { PaymentConfirmation } from "./payment-confirmation";
import { PaymentIntent } from "@stripe/stripe-js";
import { useTranslation } from 'react-i18next';

interface IProps {
  customAmount?: number | null;
}

const CheckoutForm = ({ customAmount }: IProps) => {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.invoice.payment-form.checkout-form";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<null | string>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const [paymentIntent, setPaymentIntent] = useState<null | PaymentIntent>(
    null
  );

  const handleSubmit = async () => {
    console.log("submitting");

    if (!stripe || !elements || isProcessing) {
      return;
    }

    setIsProcessing(true);

    const confirmParams: any = {
        return_url: `${window.location.href}`,
    };

    if (customAmount && customAmount > 0) {  // Ensure customAmount is positive
        confirmParams.amount = customAmount * 100; // Assuming customAmount is in dollars, convert to cents for Stripe
    }

    // @ts-ignore
    const confirmResponse = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.href}`,
      },
      redirect: "if_required",
    });

    const { error } = confirmResponse;

    if (
      error &&
      (error.type === "card_error" || error.type === "validation_error")
    ) {
      if (error.message) {
        setIsProcessing(false);
        setMessage(error?.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    } else {
      if (confirmResponse && confirmResponse.paymentIntent) {
        setIsProcessing(true);
        console.log(confirmResponse.paymentIntent);
        setPaymentIntent(confirmResponse.paymentIntent);
      }

      setIsProcessing(false);
    }
  };

  // useEffect(() => {
  //   console.log(document.referrer);
  // }, []);

  return (
    <div>
      <form id="payment-form" onSubmit={handleSubmit}>
        <>
          {paymentIntent ? (
            <PaymentConfirmation paymentIntent={paymentIntent} />
          ) : (
            <>
              <PaymentElement id="payment-element" />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <Button
                  primary
                  onClick={handleSubmit}
                  style={{
                    borderWidth: 1,
                    borderColor: "gray",
                    borderRadius: 5,
                    padding: 10,
                  }}
                  id="submit"
                >
                  <span id="button-text">
                    {isProcessing ? tr("Processing ... ") : tr("Pay now")}
                  </span>
                </Button>
              </div>
            </>
          )}
          {message ? <div id="payment-message">{message}</div> : null}
        </>
      </form>
    </div>
  );
};

export { CheckoutForm };
