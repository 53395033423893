import { useState } from "react";
import { TextArea } from "styles";
import { useRequest } from "../requestContext";
import { useTranslation } from 'react-i18next';

export default function TextQuestion({ selectedOption, setSelectedOption }) {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.question.questionType.text";
  const tr = (key) => t(`${tBase}.${key}`);

  const { dispatch } = useRequest();
  const handleTextAreaChange = (e) => {
    let { value } = e.target;
    if (value.trim()) {
      setSelectedOption({ id: "1", option: e.target.value });
    } else {
      setSelectedOption();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      dispatch({ type: "next" });
    }
  };
  return (
    <TextArea
      value={selectedOption[0]?.option || ""}
      placeholder={tr("Your answer...")}
      minRows={4}
      onKeyDown={handleKeyDown}
      onChange={handleTextAreaChange}
      onClick={(e) => e.stopPropagation()}
    />
  );
}
