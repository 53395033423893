import { createContext, useContext, useEffect, useState } from "react";

const ModalContext = createContext();

function ModalProvider(props) {
  const [show, setShow] = useState(false);

  return (
    <ModalContext.Provider
      value={{ show, setShow }}
      children={props.children}
    />
  );
}

const useModal = () => useContext(ModalContext);

export { ModalProvider, useModal };
