import React from "react";
import { useTranslation } from 'react-i18next';

const EULA = () => { 
const { t } = useTranslation();
const tBase = "pages.unprotected.eula.index";
const tr = (key) => t(`${tBase}.${key}`);

  return (
  <div className="eula">
    <h1>{tr("End User License Agreement (EULA)")}</h1>

    <p>
      {tr("This End User License Agreement ('Agreement') is a legal agreement between you ('User' or 'You') and EServ ('Company' or 'We'), for the use of the EServ software application ('Software').")}
    </p>

    <p>
      {tr("By installing, copying, or otherwise using the Software, you agree to be bound by the terms of this Agreement. If you do not agree to the terms of this Agreement, do not install or use the Software.")}
    </p>

    <h2>1. {tr("License Grant")}</h2>
    <p>
      {tr("The Company grants you a revocable, non-exclusive, non-transferable, limited license to download, install, and use the Software solely for your internal business purposes, strictly in accordance with the terms of this Agreement.")}
    </p>

    <h2>2. {tr("Restrictions")}</h2>
    <p>{tr("You agree not to, and you will not permit others to")}:</p>
    <ul>
      <li>
        {tr("License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose, or otherwise commercially exploit the Software or make the Software available to any third party.")}
      </li>
      <li>
        {tr("Modify, make derivative works of, disassemble, decrypt, reverse compile, or reverse engineer any part of the Software.")}
      </li>
      <li>
        {tr("Remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) of the Company or its affiliates, partners, suppliers, or the licensors of the Software.")}
      </li>
    </ul>

    <h2>3. {tr("Ownership")}</h2>
    <p>
      {tr("The Software is licensed, not sold. The Company retains all right, title, and interest in and to the Software, including all intellectual property rights therein.")}
    </p>

    <h2>4. {tr("Updates and Maintenance")}</h2>
    <p>
      {tr("The Company may provide updates, enhancements, or maintenance for the Software at its sole discretion. The Company is not obligated to provide any updates or maintenance.")}
    </p>

    <h2>5. {tr("Support")}</h2>
    <p>
      {tr("The Company may provide you with support services related to the Software ('Support'). Any supplemental software code provided to you as part of the Support shall be considered part of the Software and subject to the terms of this Agreement.")}
    </p>

    <h2>6. {tr("Termination")}</h2>
    <p>
      {tr("This Agreement is effective until terminated. Your rights under this Agreement will terminate automatically or otherwise cease to be effective without notice from the Company if you fail to comply with any term(s) of this Agreement. Upon termination of this Agreement, you shall cease all use of the Software and destroy all copies, full or partial, of the Software.")}
    </p>

    <h2>7. {tr("Disclaimer of Warranties")}</h2>
    <p>
      {tr("The Software is provided to you 'AS IS' and 'AS AVAILABLE' and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company expressly disclaims all warranties, whether express, implied, statutory, or otherwise, with respect to the Software, including all implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement.")}
    </p>

    <h2>8. {tr("Limitation of Liability")}</h2>
    <p>
      {tr("To the fullest extent permitted by applicable law, in no event shall the Company be liable for any special, incidental, indirect, or consequential damages whatsoever arising out of or in any way related to your use of or inability to use the Software.")}
    </p>

    <h2>9. {tr("Governing Law")}</h2>
    <p>
      {tr("This Agreement shall be governed by and construed in accordance with the laws of the jurisdiction in which the Company is headquartered, without regard to its conflict of law principles.")}
    </p>

    <h2>10. {("Entire Agreement")}</h2>
    <p>
      {tr("This Agreement constitutes the entire agreement between you and the Company regarding the use of the Software and supersedes all prior and contemporaneous written or oral agreements between you and the Company.")}
    </p>

    <h2>11. {tr("Amendments to this Agreement")}</h2>
    <p>
      {tr("The Company reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.")}
    </p>

    <h2>12. {tr("Contact Information")}</h2>
    <p>{tr("If you have any questions about this Agreement, please contact us at")}:</p>

    <address>
      EServ
      <br />
      info@eserv.com
      <br />
    </address>
  </div>
)};

export default EULA;
