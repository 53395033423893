import styled from "styled-components";
import Banner from "../business/placeholderpictures/pic1.png";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from 'react-i18next';


// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.contracts.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const history = useHistory();
  const params = useParams();
  const [termschecked, setTermsChecked] = useState(false);
  const [signed, setSigned] = useState(false);

  const contract_terms = useQuery(
		"contract_terms",
		async () =>{
			return await axios
				.get(
					`${process.env.REACT_APP_API}/api/v1/consumer/contracts?string=${params.string}`
				)
				.then((res) => res.data)
	
    });

  const logo = useQuery(
    "logo",
    async () => {
      return await axios
        .get(
					`${process.env.REACT_APP_API}/api/v1/consumer/contracts/logo?string=${params.string}`
        )
        .then((res) => res.data);
    },

  );

  const signcontract = useMutation(
    async () => {
      return await axios
        .post(
          `${process.env.REACT_APP_API}/api/v1/consumer/contracts?string=${params.string}`
        )
        .then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        setSigned(true);
      },
    }

  )
  const Logo = ()=>{
    return (
      <div style={{margin:'auto'}}>
        <Image src={`data:image/png;base64,${logo.data}`} alt="logo" />
      </div>
    )
  }
    
  if(contract_terms.isError){
    return <Con>
        <Content>

          <h1>{tr("Invalid contract key please contact the business directly")}</h1>
        </Content>
    </Con>
  }else if(contract_terms.isLoading){
      return <Con>
          <Content>
            <h1>{tr("Loading...")}</h1>
          </Content>
      </Con>
  }else if(signed){
    return <Con>
        <Content>
          <h1>{tr("The contract has been approved you may close this page")}</h1>
        </Content>
    </Con>
  }else{
  return (
    <Con>
      <Content style={{marginBottom:'20px'}}>
        <Logo/>
        <div 
          style={{
            margin:'20px',
            width: "100%",
            alignItems: "center",
          }}
        ><h1 style={{textAlign:'center', width:'100%', marginBottom:'20px'}}> {tr("Please read and agree to the Terms and Conditions below")}:</h1></div>
        <div style={{height:'20%',width:'70%',margin:'auto',borderRadius:"15px",padding:'20px',overflowY:'scroll',background:'#ccc'}}>
          <p>{contract_terms?.data}</p>
          <input style={{display:'inline'}} type="checkbox" id="terms" name="terms" checked={termschecked} onChange={()=>{setTermsChecked(!termschecked)}}/>
          <p style={{display:"inline",margin:'10px', marginTop:'50px'}}>{tr("I have read and agree to the above Terms and Conditions")}</p>
        </div>
        <button style={{marginTop:'50px', float:'right',color:'#fff',padding:'8px', background:'#ff4f23',borderRadius:'5px'}} onClick={()=>{if(termschecked){
          signcontract.mutate()
        }else{alert('Please read and agree to the Terms and Conditions')}}}>{tr("Confirm")}</button>
      </Content>
    </Con>
  );
  }}


const Image = styled.img`
  height: 300px;
`;

const EachService = styled.div``;

const ServiceTitle = styled.h3``;

const ServiceDetail = styled.div`
  line-height: 1.5rem;
`;

const ContainerCon = styled.div`
  border: 1px solid #aeb6bf;
  padding: 0 1rem 1rem 1rem;
  border-radius: 10px;
  margin: 1rem 0;
`;

const ContainerDetail = styled.div``;

const Con = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  width: 100%;
  align-self: center;
  max-width: 900px;
`;
