import axios from "axios";
import { useQuery } from "react-query";
import styled from "styled-components";
import { useModal } from "contexts/modalContext";
import { SubtleButton } from "components/button";
import AddressField from "containers/addressField";
import PhoneNumberField from "containers/phoneNumberField";
import { RiErrorWarningLine } from "react-icons/ri";
import { useTranslation } from 'react-i18next';

export default function Info({ data, handleAddress, address: propAdd }) {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.question.summary.components.info";
  const tr = (key) => t(`${tBase}.${key}`);

  const { setShow } = useModal();

  const populateAddresses = data.addresses?.map((address) => {
    return (
      <div key={address.id} className="space-x-2 mb-1">
        <input
          type="radio"
          name="address"
          checked={propAdd === address.id}
          onChange={() => handleAddress(address.id)}
        />
        <span>{address.address}</span>
      </div>
    );
  }) || (
    <InfoRequired>
      <RiErrorWarningLine />
      {tr("Address required")}
    </InfoRequired>
  );

  return (
    <div>
      <InfoCon>
        <div>{tr("Name")}</div>
        <div>{data.name}</div>
      </InfoCon>
      <InfoCon>
        <div>
          {tr("Address")}
          <SubtleButton
            size="small"
            primary
            style={{ marginLeft: "1rem" }}
            onClick={() =>
              setShow({
                component: <AddressField cancel={() => setShow()} />,
                label: tr("Edit Address"),
              })
            }
          >
            {tr("Add/Edit")}
          </SubtleButton>
        </div>
        <div>{populateAddresses}</div>
      </InfoCon>
      <InfoCon>
        <div>{tr("Email")}</div>
        <div>{data.email}</div>
      </InfoCon>
      <InfoCon>
        <div>
          {tr("Phone Number")}
          <SubtleButton
            size="small"
            primary
            style={{ marginLeft: "1rem" }}
            onClick={() =>
              setShow({
                component: <PhoneNumberField cancel={() => setShow()} />,
                label: tr("Edit Address"),
              })
            }
          >
            {tr("Add/Edit")}
          </SubtleButton>
        </div>
        <div>
          {data.phone || (
            <InfoRequired>
              <RiErrorWarningLine />
              {tr("Phone Number required")}
            </InfoRequired>
          )}
        </div>
      </InfoCon>
    </div>
  );
}

const InfoCon = styled.div`
  > div:first-child {
    font-weight: bold;
    font-size: 0.9rem;
  }
  margin-bottom: 0.5rem;
`;

const InfoRequired = styled.div`
  color: red;
  line-height: 1;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
